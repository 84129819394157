<template>
  <div class="select-wrapper" :class="{ 'select-wrapper--inputSelect': type === 'inputSelect' }">
    <label
      v-if="label"
      class="block mb-[10px] text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white text-left 2xl:text-[14px] 2xl:leading-[16px]"
    >
      {{ label }}
      <span class="requireStar" v-if="requireStar">*</span>
    </label>
    <component 
      :is="componentName"
      class="select_root"
      :class="{ 'select_root--inputSelect': type === 'inputSelect' }"
      :name="name"
      :value="value"
      item-text="value"
      item-value="key"
      :hide-details="hideDetails"
      :items="items"
      :label="placeholder"
      attach
      solo
      :rules="rules"
      @change="onChange"
      :no-data-text="noDataText"
      :disabled="disabled"
    >
    </component>
  </div>
</template>

<script>
import { VSelect, VAutocomplete } from 'vuetify/lib'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [ String, Boolean ],
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    noDataText: {
      type: String,
      default: 'No data available',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    items: {
      type: [Object, Array],
      default: () => {},
    },
    requireStar: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: 'VSelect',
    },    
    rules: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VSelect,
    VAutocomplete,
  },
  methods: {
    onChange(item) {
      this.$emit('change', {
        field: this.name || '',
        value: item,
      });
    },
  },
};
</script>

<style lang="scss">
@import './Select.scss';
</style>
