<template functional>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.34766 4.86768C3.66953 4.86768 3.11719 4.31533 3.11719 3.63721C3.11719 2.95908 3.66953 2.40674 4.34766 2.40674C5.02578 2.40674 5.57812 2.95908 5.57812 3.63721C5.57812 4.31533 5.02852 4.86768 4.34766 4.86768ZM4.34766 3.22705C4.1207 3.22705 3.9375 3.41025 3.9375 3.63721C3.9375 3.86416 4.1207 4.04736 4.34766 4.04736C4.57461 4.04736 4.75781 3.86416 4.75781 3.63721C4.75781 3.41025 4.57461 3.22705 4.34766 3.22705Z"
      fill="white"
      stroke="white"
      stroke-width="0.2"
    />
    <path
      d="M14.7578 13.6019C14.7469 13.807 14.5883 13.971 14.3859 13.9902L14.3832 13.9902L11.143 14.28C10.9352 14.2992 10.7437 14.157 10.7027 13.9519L10.4676 12.7706L9.28633 12.5327C9.125 12.4999 8.99648 12.3742 8.96367 12.2101L8.72851 11.0288L7.54726 10.7937C7.40508 10.7663 7.2875 10.6624 7.24101 10.5257L6.75703 9.13119C5.19023 9.57962 3.48945 9.15306 2.32461 7.98822C0.563671 6.22728 0.563671 3.36439 2.32461 1.60619C3.17226 0.753061 4.30703 0.282749 5.51289 0.282749C8.00937 0.282749 10.0273 2.30619 10.0273 4.7972C10.0273 5.27845 9.95351 5.74603 9.80859 6.189L14.6375 10.9714C14.7141 11.048 14.7578 11.1546 14.7578 11.264L14.7578 13.5827C14.7578 13.5882 14.7578 13.5964 14.7578 13.6019ZM5.51289 1.10306C4.52578 1.10306 3.59883 1.48587 2.90156 2.18587C1.46055 3.62689 1.46055 5.96751 2.90156 7.40853C3.6043 8.11126 4.54766 8.48587 5.51016 8.48587C5.96406 8.48587 6.4207 8.40111 6.86094 8.23158C7.07695 8.14681 7.32031 8.25892 7.39687 8.4804L7.93828 10.0363L9.15781 10.2796C9.31914 10.3124 9.44766 10.4382 9.48047 10.6023L9.71562 11.7835L10.8996 12.0214C11.0609 12.0542 11.1895 12.18 11.2223 12.3441L11.441 13.4324L13.4371 13.2546L8.12422 7.98822C7.96289 7.82689 7.96289 7.56712 8.12422 7.40579C10.432 5.09798 8.80508 1.10306 5.51289 1.10306ZM13.9375 11.4335L9.45586 6.99564C9.31914 7.23626 9.16328 7.46869 8.98281 7.6847L13.9375 12.5984L13.9375 11.4335Z"
      fill="white"
      stroke="white"
      stroke-width="0.2"
    />
  </svg>
</template>

<script>
export default {};
</script>
