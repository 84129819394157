import store from '@/store';
import { isEmpty } from 'ramda';
import {roundingCurrency} from "@/utils/helpers";

export const getBalanceByCurrency = (currency = 'USD') => {
  const {
    state: {
      balance: { balance },
    },
  } = store;
  if (!isEmpty(balance)) {
    const currencyTotalBalance = balance[currency][currency].total;
    const currencyReservedBalance = balance[currency][currency].reserved;
    return currencyTotalBalance - currencyReservedBalance;
  }
  return 0;
};

export const getTotalBalanceByCurrency = (currency = 'USD') => {
  const {
    state: {
      balance: { balance },
    },
  } = store;
  if (!isEmpty(balance)) {
    // TODO: if coins pair are disabled front not available to
    // calculate total amount in disabled (another) currency
    const amount = Object.values(balance).reduce((sum, current) => {
      let currExists = current[currency] ? current[currency].total : 0;
      return sum + currExists;
    }, 0);
    return roundingCurrency({ currency, currencyAmount:amount });
  }
  return 0;
};
