<template>
  <section class="font-roboto fixed w-full h-full flex flex-col justify-center items-center bg-blue-900 text-center text-white text-[18px] md:text-[30px] px-10 md:px-32 bg-no-repeat bg-cover bg-center" :style="`background-image: url(${background})`">
    <img class="w-[92px] h-auto mb-32" src="@/assets/img/auth/auth-logo.svg" alt="logo" />
    <h1 class="text-blue-500 text-[26px] md:text-[40px] mb-20">Dear users!</h1>
    <p class="mb-20"><span class="text-blue-500 font-medium">KAURI.FINANCE</span> informs you that we are undergoing maintenance at the moment.</p>
    <p>Thanks for working with us!</p>
  </section>
</template>

<script>
export default {
  computed: {
    background () {
      return require('@/assets/img/auth/auth_bg.png');
    }
  }
};
</script>
