import guest from '@/router/middleware/guest';

const meta = {
  public: true,
  middleware: [guest],
};

const routes = [
  {
    path: '/notifications',
    meta: {
      ...meta,
      shared: true,
    },
    component: () => import('@/views/Unauthorized/Notifications/Notifications.vue'),
  },
  {
    path: '/status-success',
    component: () => import('@/views/PaymentStatusSuccess.vue'),
    meta: {
      ...meta,
      shared: true,
    },
  },
  {
    path: '/status-fail',
    component: () => import('@/views/PaymentStatusFail.vue'),
    meta: {
      ...meta,
      shared: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Unauthorized/Auth/Auth.vue'),
    redirect: '/auth/login',
    meta,
    children: [
      {
        path: '/auth/login',
        name: 'login',
        meta,
        component: () => import('@/views/Unauthorized/Auth/Login/Login.vue'),
      },
      {
        path: '/auth/login-verify',
        name: 'login.verify',
        component: () => import('@/views/Unauthorized/Auth/Login/GoogleTotpLogin.vue'),
      },
      {
        path: '/auth/registration',
        name: 'registration',
        meta,
        component: () => import('@/views/Unauthorized/Auth/Registration/Registration.vue'),
      },
      {
        path: '/auth/registration/verification',
        name: 'registration.email.verification',
        meta,
        component: () => import('@/views/Unauthorized/Auth/Verification/RegistrationEmailVerification.vue'),
      },
      {
        path: '/auth/login/resend',
        name: 'login.resend',
        meta,
        component: () => import('@/views/Unauthorized/Auth/Verification/ResendEmail.vue'),
      },
      {
        path: '/auth/reset',
        name: 'reset',
        meta,
        component: () => import('@/views/Unauthorized/Auth/Reset/Reset.vue'),
      },
      {
        path: '/auth/reset/confirm',
        name: 'reset.confirm',
        meta,
        component: () => import('@/views/Unauthorized/Auth/ResetConfirm/ResetConfirm.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/Unauthorized/Landing/Landing.vue'),

  },
  {
    path: '/limits',
    name: 'limits',
    component: () => import('@/views/Unauthorized/Limits/Limits.vue'),
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/Unauthorized/Invoice/Invoice.vue'),
    meta: {
      ...meta,
      shared: true,
    },
  },
  {
    path: '/zendesk',
    name: 'zendesk',
    component: () => import('@/views/Unauthorized/Zendesk/Zendesk.vue'),
    meta: {
      ...meta,
      shared: true,
    },
  },
  {
    path: '/invoice-gateway',
    name: 'invoice.gateway',
    meta,
    shared: true,
    component: () => import('@/views/Unauthorized/Invoice/InvoiceGateway/InvoiceGateway.vue'),
  },
  {
    path: '/invoice-statements',
    name: 'invoice.statement',
    component: () => import('@/views/Unauthorized/InvoiceStatements/InvoiceStatement.vue'),
    meta,
    shared: true,
    children: [
      {
        path: '/invoice-statements/waiting',
        name: 'invoice.waiting',
        meta,
        shared: true,
        component: () => import('@/views/Unauthorized/InvoiceStatements/Waiting/Waiting.vue'),
      },
      {
        path: '/invoice-statements/statement',
        name: 'invoice.statements.statement',
        meta,
        shared: true,
        component: () => import('@/views/Unauthorized/InvoiceStatements/Statement/Statement.vue'),
      },
    ],
  },
  // {
  //   path: "/404",
  //   name: 'PageNotFound',
  //   component: () => import('@/views/Application/Account/PageNotFound/PageNotFound.vue'),
  // },
  // {
  //   path: "*",
  //   redirect: '/404'
  // },
];

export default routes.map(route => {
  return {
    ...route,
    meta: route.meta || meta,
  };
});
