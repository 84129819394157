<template>
  <header id="header" :class="['Landing_header', { 'Landing_header--sticky': scrolledDown }]" ref="header">
    <div v-if="$route.path == '/'" class="Landing_header_News" :class="{'Landing_header_News--hide': isNewsVisible == 'hide'}">
      <div class="Landing_header_NewsContent">
        <p>KAURI.FINANCE does not provide services to citizens of the Russian Federation and legal entities founded by citizens of the Russian Federation</p>
        <template v-if="false">
          <div class="Landing_header_NewsSpoilerBody">
            <p>BTC — 357a3So9CbsNfBBgFYACGvxxS6tMaDoa1P</p>
            <p>ETH — 0x165CD37b4C644C2921454429E7F9358d18A45e14</p>
            <p>USDT (trc20) — TEFccmfQ38cZS1DTZVhsxKVDckA8Y6VfCy</p>
            <p><a href="https://twitter.com/FedorovMykhailo/status/1497549813205848068" target="_blank" rel="noopener noreferrer">Link Twitter</a>. <a href="https://bank.gov.ua/ua/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi" target="_blank" rel="noopener noreferrer">NBU multicurrency account to support the Army of Ukraine</a></p>
            <p>GLORY TO UKRAINE!</p>
          </div>
          <div class="Landing_header_NewsSpoilerButton"><button class="button transp" @click="handleToggleNewsVisible">{{newsButtonTitle}}</button></div>
        </template>
      </div>
    </div>
    <div class="Landing_headerWrapper">
      <a class="Landign_headerLogo" href="/"><img src="@/assets/img/_common/financeLogoBlueWhite.svg" alt="site-logo" /></a>
      <nav :class="['Landing_headerNav', { Landing_headerNavOpen: open }]">
        <a :href="nanLink.anchor"
           :key="nanLink.anchor"
           v-for="nanLink in nanLinks"
           @click="toggleMenu('hide'); onRedirectUrl()"
           class="Landign_headerNavLink"
           v-smooth-scroll="{ offset: -hederHeight }"
        >
          {{ nanLink.linkName }}
        </a>

        <div class="Landing_headerNavBtns" @click="toggleMenu('hide')">
          <router-link class="button lightblue" :to="{ name: 'login' }">Login</router-link>
          <router-link class="button transp" :to="{ name: 'registration' }">Registration</router-link>
        </div>
      </nav>
      <div @click="toggleMenu()" :class="['Landing_headerToggler', { Landing_headerTogglerOpen: open }]">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data: () => ({
    isNewsVisible: localStorage.getItem('isNewsVisible') || 'show',
    activeTab: 'market',
    scrolledDown: false,
    open: false,
    hederHeight: 76,
    isMounted: false,
    test: '',
    nanLinks: [
      {
        linkName: 'Home',
        anchor: '#home'
      },
      {
        linkName: 'CryptoBroker',
        anchor: '#crypto-broker'
      },
      {
        linkName: 'Crypto Processing',
        anchor: '#crypto-processing'
      },
      {
        linkName: 'Payment Hub',
        anchor: '#payment-hub'
      },
      {
        linkName: 'Fund',
        anchor: '#invest-fund'
      },
      {
        linkName: 'KAURI.PAY',
        anchor: '#kauri-pay'
      },
      {
        linkName: 'Verification',
        anchor: '#verification'
      },
      {
        linkName: 'NFT Tokens',
        anchor: '#nft-broker'
      }
    ]
  }),
  computed: {
    newsButtonTitle() {
      return this.isNewsVisible == 'show' ? 'Collapse' : 'Expand'
    },
  },
  methods: {
    onRedirectUrl() {
      if (this.$route.path !== '/') this.$router.push('/')
    },
    handleScroll() {
      if (window && window.scrollY > 0) {
        this.scrolledDown = true;
      } else {
        this.scrolledDown = false;
      }
    },
    toggleMenu(param) {
      if (param === 'hide') {
        this.open = false;
      } else {
        this.open = !this.open;
      }
    },
    handleToggleNewsVisible() {
      this.isNewsVisible = this.isNewsVisible == 'show' ? 'hide' : 'show';
      localStorage.setItem('isNewsVisible', this.isNewsVisible);
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.isMounted = true;
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

/* scroll-style */
* {
  scrollbar-color: #00ffff #001023;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}
*::-webkit-scrollbar-track {
  background: #001023;
}
*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #00ffff;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 40px;
  padding: 6px 22px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  border-radius: 9px;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s;

  @media only screen and (min-width: $tablet-width) {
    padding: 8px 32px;
    min-height: 50px;
    font-size: 16px;
    line-height: 20px;
  }

  &.lightblue {
    font-weight: 700;
    color: #fff;
    background: #00f3f4;
    border-color: #00ffff;
    &:hover {
      background: #00ffff;
      box-shadow: 0 0 8px 5px rgba(0, 255, 255, 0.1);
    }
  }

  &.transp {
    background: rgba(0, 224, 255, 0.1);
    color: #00ffff;
    border-color: #00ffff;
    &:hover {
      background: rgba(0, 224, 255, 0.3);
    }
  }
}
</style>

<style lang="scss" scoped>
@import './Header.scss';
</style>
