import { generateObjectForExportXLSX, setFee, getCurrenciesListToHide  } from "@/utils/helpers"

const getters = {
  getUserInfoFullName: (state) => state.userInfo?.full_name,
  getTypeUserInfo: (state) => state.userInfo?.account_type,
  isVerified: state => state.userInfo.account_type === "VERIFIED",
  userHasProviderPermissions: state => state.userInfo.account_type === "VERIFIED" || state.userInfo.account_type === "BUSINESS",
  invoiceOrderProcessingRules: state => {
    return Object.entries( state?.invoice_order_processing_rules ).reduce( ( accumulator, currentValue ) => {
      if ( currentValue?.[ 1 ]?.INVOICE_CREATION?.is_enabled ) accumulator.push( { value: currentValue?.[ 0 ], ...currentValue?.[ 1 ] } )
      return accumulator
    }, [] ).filter(currency => getCurrenciesListToHide().every(el=>el !== currency.value) )
  },
  
  getGeneralFees: ( state ) => ( path ) => {
    return setFee( {
      percent_fee: state.generalFeesLimitsFlatten[ `${ path }.percent_fee` ],
      static_fee: state.generalFeesLimitsFlatten[ `${ path }.static_fee` ],
      currency: path.split( "." )[ 2 ]
    } )
  },
  
  getGeneralLimit: ( state ) => ( path ) => {
    const value = state.generalFeesLimitsFlatten[ path ]
    return value ? `${ value } ${ path.split( "." )[ 2 ] }` : "-"
  },
  
  getStatusDisableDescriptionWithdrawal: ( state ) => ( currency, method, subMethod ) => {
    const status = subMethod ? state.userWithdrawalRules?.[ currency ]?.[ method ]?.[ subMethod ]?.disable_description
      : state.userWithdrawalRules?.[ currency ]?.[ method ]?.disable_description
    
    return status === "RESET_PASSWORD_LOCK_IN_PROGRESS" ? "BLOCKED"
      : status === "ACCOUNT_VERIFICATION_REQUIRED" ? "NOT_VERIFIED"
        : status === "DISABLED" ? "DISABLED" : "OK"
  },

  getStatusDisableDescriptionInternalMovement: ( state ) => ( currency ) => {
    const status = state.userInternalMovementRules?.[ currency ]?.CROSS_ACCOUNT?.disable_description
    
    return status === 'RESET_PASSWORD_LOCK_IN_PROGRESS' ? 'BLOCKED'
      : status === 'ACCOUNT_VERIFICATION_REQUIRED' ? 'NOT_VERIFIED'
        : status === 'DISABLED' ? 'DISABLED' : 'OK'
  },
  
  getOrdersHistoryAllForXLSX: state => Object.values( state.ordersHistoryForXLSX ).map( ( item, index ) => {
    const [itemObj] = generateObjectForExportXLSX( item, index )
    delete itemObj["Buy"]
    delete itemObj["Sell"]
    delete itemObj["Rate"]
    
    return itemObj
  } ),
  
  getOrderHistoryForXLSX: state => ( order_type ) => Object.values( state.ordersHistoryForXLSX ).filter( item => order_type === item?.order_type ).map( ( item, index ) => {
    const [itemObj, currencyOperationBuy, currencyOperationSell] = generateObjectForExportXLSX( item, index )
    
    switch(item?.order_type.toUpperCase()) {
      case "EXCHANGE":
        delete itemObj["Transaction Hash"]
        return {
          ...itemObj,
          "Buy": item?.details?.currency_to_get_amount ? `${item?.details?.currency_to_get_amount} ${currencyOperationBuy}` : "-",
          "Sell": item?.details?.currency_to_spend_amount ? `-${item?.details?.currency_to_spend_amount} ${currencyOperationSell}` : "-",
          "Rate": item?.details?.price ? item?.details?.price : "-",
        }
      case "WITHDRAWAL":
      case "INTERNAL_MOVEMENT":
      case "INVOICE":
        delete itemObj["Transaction Hash"]
        return itemObj
      default:
        return itemObj
    }
  } ),
  getChangeVerificationMethod: (state) => state.changeVerificationMethod.step
}

export default getters
