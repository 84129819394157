import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';
import { helpersGetNotificationPageUrl } from '@/utils/helpers';

const actions = {
  apiCreateInvoice(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('invoice/', {
          pay_account_email: payload.payee_email,
          currency: payload.invoice_currency,
          amount: payload.invoice_amount,
          comment: payload.invoice_comment,
          payment_option: payload.invoice_type,
          additional_info: {
            success_url: helpersGetNotificationPageUrl("depositPbt"),
            fail_url: helpersGetNotificationPageUrl("depositPbt"),
          }
        })
        .then(response => {
          resolve(response);
          showAppNotification('success', 'Invoice has been successfully issued!');
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiGetInvoiceInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .get(`invoice/details?order_id=${payload}`)
        .then(response => {
          commit('UPDATE_INVOICE', response.data);
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  apiPayPublicInvoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('invoice/pay/public', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiPayPublicCalculateInvoice({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get('invoice/pay/public/calculate', { params })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  apiPayPrivateInvoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('invoice/pay', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateUserInvoicePayemntStatus({ commit }, payload) {
    commit('UPDATE_INVOICE_PAYMENT_STATUS', payload);
  },
  updateInvoiceGatewayForm({ commit }, payload) {
    commit('UPDATE_INVOICE_GATEWAY_FORM', payload);
  }
};

export default actions;
