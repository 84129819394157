<template>
  <notifications class="Notification_root" width="320" :duration="10000" group="main">
    <template slot="body" slot-scope="props">
      <div
        class="Notification__wrapper"
        :class="[
          {
            'Notification__wrapper--success': props.item.type === 'success',
            'Notification__wrapper--error': props.item.type === 'error',
          },
        ]"
        @click="props.close"
      >
        <div class="Notification__container">
          <div class="Notification__icon">
            <iconClose v-if="props.item.type === 'error'" />
            <iconLetter v-if="props.item.type === 'success'" />
          </div>
          <div class="Notification__text-wrapper" v-html="props.item.text" />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import { iconClose } from '@/assets/icons/arrows';
import { iconLetter } from '@/assets/icons/common';
export default {
  components: {
    iconClose,
    iconLetter,
  },
};
</script>

<style lang="scss">
.Notification_root {
  margin: 10px;
  .Notification__wrapper {
    padding: 10px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.34);
    border: 1px solid #fff;
    .Notification__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 7px 10px;
      border-radius: 2px;
    }
    &--success {
      .Notification__container {
        background: #5abc67;
      }
    }
    &--error {
      .Notification__container {
        background: #ff556d;
      }
    }
    .Notification__icon {
      display: block;
      flex: 0 1 22px;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 2px;
      position: relative;
      svg {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }
  }
  .Notification__text-wrapper {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    max-width: 250px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
