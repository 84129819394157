import store from '@/store';

const modifyCashboxFormToRequestPayload = currency => {
  const {
    state: {
      depositAndWithdrawal: { depositAndWithdrawalCashboxForm },
    },
  } = store;
  const { phone_number, contact_name, city, ...rest } = depositAndWithdrawalCashboxForm;
  return {
    additional_info: {
      phone_number,
      contact_name,
      city,
      street: '',
      communication_type: 'telegram',
      meeting_time: '00:00',
    },
    ...currency,
    ...rest,
  };
};

export { modifyCashboxFormToRequestPayload };
