export default function guest({ next, store, nextMiddleware, to }) {
  const authenticated = !!store.state.user.token.length;
  if (authenticated && !to.meta.shared) {
    return next({
      name: 'account',
    });
  } else if (to.name == 'invoice' && localStorage.getItem('kauri_finance_token')) {
    store.dispatch('user/logoutAction', true);
  }
  return nextMiddleware();
}
