import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';

const serialize = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
const filterAndSerialize = obj => {
  let clonedObj = JSON.parse(JSON.stringify(obj));
  Object.keys(clonedObj).forEach(field => {
    if (!clonedObj[field]) delete clonedObj[field];
  });
  if (Object.values(clonedObj).length) {
    return serialize(clonedObj);
  }
  return null;
};

const actions = {
  populateProvidersHistoryFilter({ state, commit }, data) {
    if (data.field && Object.keys(state.providersHistoryFilters).includes(data.field)) {
      commit('POPULATE_PROVIDERS_HISTORY_FILTER', data);
    }
  },
  populateProvidersHistoryDateFilter({ state, commit }, data) {
    if (data.field && Object.keys(state.providersHistoryDateFilter).includes(data.field)) {
      commit('POPULATE_PROVIDERS_HISTORY_DATE_FILTER', data);
    }
  },
  updateActivateProviderForm({ state, commit }, data) {
    if (data.field && Object.keys(state.activateProviderForm).includes(data.field)) {
      commit('UPDATE_ACTIVATE_PROVIDER_FORM', data);
    }
  },
  changeProvidersHistoryPage({ commit }, page) {
    commit('CHANGE_PROVIDERS_HISTORY_PAGE', page);
  },
  async apiGetUserProviders({ commit }) {
    try {
      const {
        data: { payment_accounts },
      } = await api.get('payment_provider/account/list/');
      commit( 'UPDATE_USER_PROVIDERS', payment_accounts );
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
    }
  },
  async apiGetSupportedProviderIntegrations({ commit, dispatch }) {
    try {
      const {
        data: { providers },
      } = await api.get('payment_provider/supported_integrations');
      commit('UPDATE_SUPPORTED_PROVIDERS_INTEGRATIONS', providers);
      if (providers && providers.length) {
        dispatch('updateActivateProviderForm', {
          field: 'provider',
          value: providers[0],
        });
      }
      return providers;
    } catch (error) {
      return error;
    }
  },
  async apiGetFieldsArrayBySelectedProvider(context, providerName) {
    return new Promise((resolve, reject) => {
      api
        .get(`payment_provider/account_setup_fields?provider=${providerName}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  apiGetUserProvidersTransactionsHistory({ state, commit, rootState }) {
    const subAccountId = rootState?.user?.userSubAccountId;
    const dateFilterHasAllValues = Object.values(state.providersHistoryDateFilter).every(item => !!item);
    const serializedParams = filterAndSerialize({
      ...state.providersHistoryFilters,
      ...(dateFilterHasAllValues && state.providersHistoryDateFilter),
    });
    return new Promise((resolve, reject) => {
      api
        .get(`payment_provider/transaction_history?${serializedParams}&sub_account_id=${subAccountId}`)
        .then(response => {
          if (response && response.data) {
            commit('SET_USER_PROVIDERS_TRANSACTIONS_HISTORY_PAGES_COUNT', response.data.pages_count);
            commit('UPDATE_USER_PROVIDERS_TRANSACTIONS_HISTORY', response.data.results);
            resolve(response.data.results);
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail)
          reject(error);
        });
    });
  },
  apiActivateProvider({ dispatch }, form) {
    console.log(form)
    return new Promise((resolve, reject) => {
      api
        .post(`payment_provider/account/create`, form)
        .then(response => {
          dispatch('apiGetUserProviders');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiUpdateProvider({ dispatch }, request) {
    console.log(request);
    return new Promise((resolve, reject) => {
      api
        .put(`payment_provider/account/update`, request)
        .then(response => {
          dispatch('apiGetUserProviders');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  updateUserProvidersBalances({ commit }, payload) {
    commit('UPDATE_USER_PROVIDERS_BALANCES', payload);
  },
  async apiGetProvidersFee({ commit }, provider) {
    try {
      const response = await api.get('payment_provider/user_assigned/list', provider);
      commit('SET_PROVIDERS_FEE', response.data.providers);
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
    }
  },
  apiCreateRoute({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      api
        .post(`payment_provider/route/create`, form)
        .then(response => {
          dispatch('apiGetUserProviders');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiUpdateRoute({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      api
        .put(`payment_provider/route/update`, form)
        .then(response => {
          dispatch('apiGetUserProviders');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
};

export default actions;
