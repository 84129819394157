<template functional>
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M8.44416 0L7.42131 1.02285L9.0795 2.67203H1.44191C0.644351 2.67203 0 3.31638 0 4.11394V6.27679H1.44191V4.11394H9.07499L7.42581 5.76762L8.44416 6.78597L11.328 3.90216C11.6073 3.62279 11.6073 3.16769 11.328 2.88381L8.44416 0Z"
        :fill="props.fillColor"
      />
      <path
        d="M10.0951 9.88677H2.46202L4.1112 8.23759L3.09285 7.21924L0.209039 10.1031C-0.0703306 10.3824 -0.0703306 10.8375 0.209039 11.1214L3.09285 14.0052L4.1112 12.9869L2.46202 11.3287H10.0951C10.8927 11.3287 11.537 10.6843 11.537 9.88677V7.72391H10.0951V9.88677Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="11.5352" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>
