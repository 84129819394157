<template functional>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M7.81001 2.29688C7.13267 0.914062 5.7147 0 4.12564 0C1.8522 0 0.000639927 1.83984 0.000639927 4.10156C0.000639927 4.71562 0.13189 5.30625 0.392046 5.85469L0.00767118 7.78359C-0.0415476 8.02969 0.174077 8.24531 0.420171 8.19844L2.37251 7.81172C2.63501 7.93594 2.90689 8.03203 3.1858 8.09766C2.56001 5.13047 4.80767 2.34609 7.81001 2.29688Z"
        :fill="props.fillColor"
      />
      <path
        d="M11.6108 8.85442C11.6788 8.71145 11.7374 8.56849 11.7866 8.42083H11.7749C12.6514 5.84505 10.8069 3.13802 8.0811 3.00442C5.73734 2.89661 3.78967 4.77395 3.78967 7.1013C3.78967 9.36067 5.62718 11.2005 7.88422 11.2029C8.49594 11.2029 9.08422 11.0693 9.63266 10.8115C11.7702 11.2333 11.6038 11.2029 11.653 11.2029C11.8756 11.2029 12.042 10.999 11.9975 10.7833L11.6108 8.85442Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="11.2031" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
