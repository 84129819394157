<template functional>
  <svg width="13" height="14" viewBox="0 0 13 14" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.3599 11.3233C4.5894 11.3233 4.81891 11.0938 4.81891 10.8643V6.58011C4.89541 6.2741 4.66591 6.12109 4.3599 6.12109C4.05388 6.12109 3.90088 6.2741 3.90088 6.58011V10.8643C3.90088 11.0938 4.13039 11.3233 4.3599 11.3233Z"
      :fill="props.fillColor"
    />
    <path
      d="M6.5028 11.3233C6.73231 11.3233 6.96182 11.0938 6.96182 10.8643V6.58011C6.96182 6.2741 6.73231 6.12109 6.5028 6.12109C6.2733 6.12109 5.96729 6.2741 5.96729 6.58011V10.8643C5.96729 11.0938 6.19679 11.3233 6.5028 11.3233Z"
      :fill="props.fillColor"
    />
    <path
      d="M8.4888 11.3233C8.71831 11.3233 8.94782 11.0938 8.94782 10.8643V6.58011C8.94782 6.3506 8.71831 6.12109 8.4888 6.12109C8.25929 6.12109 8.02979 6.3506 8.02979 6.58011V10.8643C8.02979 11.0938 8.25929 11.3233 8.4888 11.3233Z"
      :fill="props.fillColor"
    />
    <path
      d="M12.6989 3.82514C12.6989 2.60109 11.7044 1.68306 10.5569 1.68306H9.33283V1.07104C9.33283 0.459016 8.87381 0 8.26179 0H4.66616C4.05414 0 3.59512 0.459016 3.59512 1.07104V1.68306H2.37108C1.22354 1.68306 0.229004 2.60109 0.229004 3.82514V4.97268H0.917529L1.68256 11.2459C1.98857 12.8525 3.13611 14 4.58966 14H8.26179C9.79185 14 10.9394 12.8525 11.1689 11.2459L11.9339 5.04918H12.6224V3.82514H12.6989ZM10.0979 11.0929C10.0214 11.9344 9.40933 12.8525 8.33829 12.8525H4.58966C3.51862 12.8525 2.9831 11.9344 2.8301 11.0929L2.06507 5.04918H10.7864L10.0979 11.0929ZM4.51316 1.07104C4.51316 0.994536 4.58966 0.918033 4.66616 0.918033H8.26179C8.33829 0.918033 8.4148 0.994536 8.4148 1.07104V1.68306H4.51316V1.07104ZM1.22354 3.82514C1.22354 3.21311 1.75906 2.6776 2.37108 2.6776H10.4804C11.0924 2.6776 11.6279 3.21311 11.6279 3.82514V3.97814H11.5514H11.4749H1.45305H1.37654H1.22354V3.82514Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
