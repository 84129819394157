import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';
const actions = {
  apiFetchFundsList({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_FUNDS_LOADED', false);
      api
        .get('invest_fund/invest_fund_list')
        .then(response => {
          console.log('response');
          commit('SET_FUNDS', response);
          commit('SET_FUNDS_LOADED', true);
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiCreateFundTransfer({ state, dispatch }, params) {
    const data = {
      currency: state.funds.currency_name,
      amount: params.amount,
    };
    // TODO: change -Exchange wallet condition
    data[params.type == 'Exchange wallet' ? 'dest_sub_account_type' : 'source_sub_account_type'] = 'TRADING';
    return new Promise((resolve, reject) => {
      api
        .post('internal_movement', data)
        .then(response => {
          dispatch('apiFetchFundsList');
          showAppNotification('success', 'Transfer success');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiCreateFundInvestment({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      api
        .post('invest_fund/investment_deposit_create', params)
        .then(response => {
          dispatch('apiFetchFundsList');
          showAppNotification('success', 'Investment request sent');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
};

export default actions;
