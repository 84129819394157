import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';

const actions = {
  apiCreateApiKey(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/api_keys/create', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiUserVerifyApiKeyCreation({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/api_keys/verify', payload)
        .then(response => {
          dispatch('user/apiFetchUserData', null, { root: true });
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiDeleteApiKey({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .delete('user/api_keys/delete', {
          data: { ...payload },
        })
        .then(response => {
          dispatch('user/apiFetchUserData', null, { root: true });
          showAppNotification('success', 'Key successfully deleted!');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiCreateWhitelistIP({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/white_ip/create', payload)
        .then(response => {
          dispatch('user/apiFetchUserData', null, { root: true });
          showAppNotification('success', 'IP successfully added!');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiDeleteWhitelistIP({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .delete('user/white_ip/delete', {
          data: { ...payload },
        })
        .then(response => {
          dispatch('user/apiFetchUserData', null, { root: true });
          showAppNotification('success', 'IP successfully deleted!');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiDeleteTelegramBot({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/telegram/unassign', payload)
        .then(response => {
          dispatch('user/apiFetchUserData', null, { root: true });
          showAppNotification('success', 'Telegram bot deleted');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', 'error');
          reject(error);
        });
    });
  },
};

export default actions;
