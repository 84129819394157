const mutations = {
  SET_APP_STATUS_TO_MAINTENANCE(state, payload) {
    state.appModeMaintenance = payload;
  },
  TOGGLE_MOBILE_MENU(state) {
    state.mobileMenuShown = !state.mobileMenuShown;
  },
  SET_MOBILE_MENU_SHOWN(state, boolean) {
    state.mobileMenuShown = boolean;
  },
  SET_WINDOW_INNER_WIDTH(state, payload) {
    state.layoutWidth = payload;
  },
  SET_THEME_COLOR(state, payload) {
    state.themeColor = payload;
    localStorage.setItem('theme-color', payload);
    document.body.classList.remove('light')
    document.body.classList.remove('dark')
    document.body.classList.add(payload)
  },
};

export default mutations;
