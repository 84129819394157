<template>
  <div class="Pay-invoicing_root">
    <div class="Pay-invoicing_wrapper">
      <div class="Invoice_logo">
        <img alt="invoice page logo" src="@/assets/img/_common/financeLogoBlue.svg" />
      </div>
      <div class="Invoice_title">
        invoice payment
        <div class="Invoice_title-back" @click="logoutActionHandler">
          <img alt="Waiting page logo" src="@/assets/img/invoice/back-arrow.svg" />
          go back
        </div>
      </div>
      <div class="Invoice_border"></div>
      <div class="Invoice_pay-inner">
        <div class="Invoice_inner__heading">
          <div class="Invoice_inner-item">
            <div class="Invoice_inner-amount">
              <img src="@/assets/img/invoice/invoice-amount.svg" alt="invoice-amount-effect" />
              <div class="Invoice_inner-amount-inner">
                {{ invoiceData.amount }}<span>{{ invoiceData.currency }}</span>
              </div>
              <span>amount</span>
            </div>
          </div>
          <div class="Invoice_inner-item">
            <div class="Invoice_inner__balances">
              <div class="Invoice_inner__subtitle">Available currencies</div>
              <div class="Invoice_inner__balances_inner">
                <span class="Wallet_inner__balances_total">{{ computedTotalBalance }}</span>
                <Select
                  :items="currencies"
                  type="inputSelect"
                  :value="amountCurrency"
                  name="currency"
                  @change="updateSelectCurrency(arguments[0], 'currency')"
                />
              </div>
              <div class="Invoice_inner__description">
                <span>Attention!</span> When paying with currency different from the invoice currency, an additional commission may be
                charged for the exchange
              </div>
            </div>
          </div>
          <div class="Invoice_inner-item">
            <div class="Invoice_inner__pay">
              <div class="Invoice_inner__pay_countdown">
                <div class="Invoice_inner__pay_countdownImg">
                  <iconCountdown fillColor="#5abc67" />
                </div>
                <vue-countdown :time="15 * 60 * 1000" v-slot="{ minutes, seconds }"> {{ minutes }}:{{ seconds }} </vue-countdown>
              </div>
              <div class="Invoice_inner__payDescription">When time runs out - this page will be refreshed with a new exchange rate</div>
              <div class="Invoice_inner__payNav">
                <OutlineButton class="Invoice_inner__payBtn" text="Pay" fullWidth @click="payInvoiceHandler" />
                <Button class="Invoice_inner__payBtn" text="cancel" fullWidth @click="logoutActionHandler" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Invoice_border Invoice_borderTop"></div>

      <div class="Pay-invoicing_orderInfo">
        <div class="Pay-invoicing_orderItem">
          <div class="Pay-invoicing_orderItemHeading">
            <span><img src="@/assets/img/invoice/order.svg" /></span>
            Order
          </div>
          {{ invoiceData.order_details.internal_id }}
        </div>
        <div class="Pay-invoicing_orderItem">
          <div class="Pay-invoicing_orderItemHeading">
            <span><img src="@/assets/img/invoice/email.svg" /></span>
            email for receipt
          </div>
          {{ invoiceData.destination_account_email }}
        </div>
        <div class="Pay-invoicing_orderItem">
          <div class="Pay-invoicing_orderItemHeading">
            <span><img src="@/assets/img/invoice/order.svg" /></span>
            Description
          </div>
          {{ invoiceData.order_details.details.comment ? invoiceData.order_details.details.comment : '--' }}
        </div>
      </div>

      <div class="Invoice_border Invoice_borderTop"></div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/_common/FormElements/Select/Select';
import { mapActions, mapState } from 'vuex';
import { getBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import { iconCountdown } from '@/assets/icons/common';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';
export default {
  data: () => ({
    invoice_id: '',
    invoiceData: '',
    amountCurrency: 'BTC',
  }),
  components: {
    Select,
    iconCountdown,
    OutlineButton,
    Button,
  },
  methods: {
    ...mapActions('invoicing', ['updateUserInvoicePayemntStatus', 'apiGetInvoiceInfo', 'apiPayPrivateInvoice']),
    ...mapActions('user', ['logoutAction']),
    updateSelectCurrency({ value }) {
      this.amountCurrency = value;
    },
    payInvoiceHandler() {
      this.apiPayPrivateInvoice({
        order_id: this.invoice_id,
        currency: this.amountCurrency,
      }).then(() => {
        this.logoutActionHandler();
      });
    },
    logoutActionHandler() {
      this.$router.push({ name: 'invoice', params: { id: this.invoice_id } });
      this.logoutAction();
    },
  },
  computed: {
    ...mapState('exchange', ['currencies']),
    computedTotalBalance() {
      return getBalanceByCurrency(this.amountCurrency);
    },
  },
  mounted() {
    this.invoice_id = this.$route.params.invoice;
    if (this.$route.params.invoice) {
      this.updateUserInvoicePayemntStatus(true);
    }
    this.apiGetInvoiceInfo(this.invoice_id).then(res => {
      this.invoiceData = res.data;
    });
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import './Pay.scss';
.Invoice_title-back {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #2af3f3;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    background: #2af3f3;
    position: absolute;
    @media only screen and (min-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
  img {
    margin: 0 6px 0 2px;
    z-index: 2;
    position: relative;
    @media only screen and (min-width: 768px) {
      margin: 0 11px 0 5px;
    }
  }
}

.Invoice_title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
