<template>
  <button
    :class="[
      'Button_button',
      `Button_${size}`,
      {
        ['Button_disabled']: disabled,
        ['Button_defaultBtn']: defaultBtn,
        ['Button_brightBtn']: brightBtn,
        ['Button_fullWidth']: fullWidth,
        ['Button_arrowRight']: btnArrowRight,
        ['Button_red']: red,
      },
    ]"
    @click="handleClick"
    :disabled="disabled"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <slot></slot>
    <span 
      v-if="($slots.icon && $slots.icon.length > 0) || fillIconColor"
      class="Button_button_Icon"
    >
      <slot
        name="icon"
        :fillIconColor="iconColor"
      ></slot>
    </span>
    <span>{{ text }}</span>
  </button>
</template>
<script>
export default {
  props: {
    defaultBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    text: {
      type: String,
      required: true,
      default: 'Submit',
    },
    fullWidth: {
      type: Boolean,
      required: false,
    },
    brightBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnArrowRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    hoverIconColor: {
      type: String,
    },
    fillIconColor: {
      type: String,
    },
  },
  data() {
    return {
      iconColor: this.fillIconColor,
    };
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      this.$emit('click')
    },
    handleMouseOver() {
      this.iconColor = this.hoverIconColor
    },
    handleMouseLeave() {
      this.iconColor = this.fillIconColor
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './Button.scss';
</style>
