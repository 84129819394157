import axios from 'axios';
import store from '@/store';

const options = {
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-US',
  },
};

let instance = axios.create(options);

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('kauri_finance_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 403) store.dispatch('user/forceLogout');
    return Promise.reject(err);
  },
);

export const api = instance;
