import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueQRCodeComponent from 'vue-qrcode-component';
import Multiselect from 'vue-multiselect';
import Notifications from 'vue-notification';
import VueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(VueSmoothScroll);

import VueTreeNavigation from 'vue-tree-navigation';
Vue.use(VueTreeNavigation);
import Maska from 'maska';
Vue.use(Maska);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import OTPInput from '@8bu/vue-otp-input';
Vue.use(OTPInput);

import vuetify from './plugins/vuetify';

import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component('vue-countdown', VueCountdown);

import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

Vue.use(Notifications);
Vue.component('multiselect', Multiselect);
Vue.component('qr-code', VueQRCodeComponent);

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false;

import '../public/css/tailwind.css'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

// test
