import { api } from '@/axios';
import { isEmpty } from 'ramda';
import { showAppNotification } from '@/utils/appNotification';
import router from '@/router';
import moment from 'moment';
import { socketPrivate } from '@/socket';

const TOKEN_EXPIRATION_TIME = 10 * 60 * 1000; // 10 mins

const serialize = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
const filterAndSerialize = obj => {
  let clonedObj = JSON.parse(JSON.stringify(obj));
  Object.keys(clonedObj).forEach(field => {
    if (!clonedObj[field]) delete clonedObj[field];
  });
  if (Object.values(clonedObj).length) {
    return serialize(clonedObj);
  }
  return null;
};

const actions = {
  async apiSignupAction(context, payload) {
    try {
      const response = await api.post('user/create', { ...payload });
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiChangePasswordAction({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/change_password/', payload)
        .then(response => {
          dispatch('apiFetchUserData');
          showAppNotification('success', 'Password changed successfully!');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiSigninAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('user/obtain_token', { ...payload })
        .then(response => {
          const {
            data: { token,  is_2fa_enabled },
          } = response;
          if (token) {
            commit('UPDATE_USER_TOTP_STATUS', is_2fa_enabled);
            commit('UPDATE_USER_TOKEN', token);
            localStorage.setItem('kauri_finance_token', token);
            resolve(response);
          }
        })
        .catch(error => {
          let textNotify = ""
          if (error.response.data.detail) {
            textNotify = error.response.data.detail
          } else if (error.response.data.indexOf('503 Service Temporarily Unavailable') !== -1) {
            textNotify = 'Request was throttled. Please try again in %x% seconds.'.replace('%x%', 10);
          } else if (error.response.data.indexOf('Request was throttled.') !== -1) {
            const responseData = error.response.data.split(' seconds');
            const seconds = responseData[0].split(' ').slice(-1)[0]
            textNotify = 'Request was throttled. Please try again in %x% seconds.'.replace('%x%', seconds);
          }
          if (textNotify) showAppNotification('error', textNotify);
          localStorage.removeItem('kauri_finance_token');
          // TO DO: change to error code on BE
          if (error.response.data.detail.includes('Please verify your email')) {
            reject('goToResend');
          } else {
            reject(error);
          }
        });
    });
  },
  apiGetWsToken({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      api
        .get('user/obtain_ws_token')
        .then(response => {
          const {
            data: { token },
          } = response;
          if (token) {
            commit('UPDATE_USER_WS_TOKEN', token);
            if (socketPrivate.readyState === 1) {
              dispatch('subscribeToUserBalance')
              dispatch('subscribeToUserOrders')
            } else {
              socketPrivate.onopen = () => {
                dispatch('subscribeToUserBalance')
                dispatch('subscribeToUserOrders')
              }
            }
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  subscribeToUserBalance({state}) {
    const { wsToken } = state
    socketPrivate.send(
      JSON.stringify({
        event: 'SUBSCRIBE',
        channel: 'balance',
        token: wsToken,
      }),
    );
  },
  subscribeToUserOrders({state}) {
    const { wsToken } = state
    socketPrivate.send(
      JSON.stringify({
        event: 'SUBSCRIBE',
        channel: 'orders',
        token: wsToken,
      }),
    );
  },
  apiGetOrdersHistory({ state, commit }, data) {
    commit('SET_ORDER_HISTORY_LOADED', false);
    commit('SET_ORDER_HISTORY_PENDING', true);
    if (data?.filter) commit('CHANGE_HISTORY_PAGE', 1);
    const historyDateFilter = JSON.parse(JSON.stringify(state.historyDateFilter))
    const dateFilterHasAllValues = Object.values(historyDateFilter).every(item => !!item);
    if (dateFilterHasAllValues) {
      historyDateFilter.till_timestamp = moment(historyDateFilter.till_timestamp, 'X').add(1, 'days').format('X');
    }
    const serializedParams = filterAndSerialize({
      ...state.historyFilters,
      ...(dateFilterHasAllValues && historyDateFilter),
    });
    return new Promise((resolve, reject) => {
      api
        .get(`/orders/history?${serializedParams}`)
        .then(response => {
          if (response && response.data && response.data.orders) {
            commit('SET_HISTORY_PAGES_COUNT', response.data.pages_count);
            commit('UPDATE_ORDERS_HISTORY', response.data.orders);
            resolve(response);
          }
        })
        .catch(e => {
          reject(e);
        })
        .finally(() => {
          commit('SET_ORDER_HISTORY_LOADED', true);
          commit('SET_ORDER_HISTORY_PENDING', false);
        });
    });
  },
  apiCancelLimitExchange({ dispatch }, order_id) {
    return new Promise((resolve, reject) => {
      api
        .post('exchange/cancel/', { order_id })
        .then(response => {
          dispatch('apiGetOrdersHistory');
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  logoutAction({ state, commit }, redirect) {
    commit('TOKEN_REFRESH_TASK', null);
    commit('RESET_USER_STATE');
    localStorage.removeItem('kauri_finance_token');
    localStorage.removeItem('kf_token_expiration_time');
    commit('UPDATE_USER_TOKEN', '');
    if (!redirect) router.push({name: "landing"});
    socketPrivate.close(1000, "logoutAction");
    setTimeout(() => {
      window.location.reload();
    }, 200)
  },
  forceLogout({ dispatch }) {
    dispatch('logoutAction');
  },
  acceptCookiesUsing({ commit }) {
    commit('SET_FINANCE_COOKIES_ACCEPTED');
  },
  async fetchUserNecessaryData({ dispatch }) {
    const KF_TOKEN_EXPIRATED_AT = localStorage.getItem('kf_token_expiration_time');
    const shouldUpdateToken = Math.ceil((KF_TOKEN_EXPIRATED_AT - new Date().getTime()) / 1000) < 60;
    console.log('Token updates in:', Math.ceil((KF_TOKEN_EXPIRATED_AT - new Date().getTime()) / 1000));
    if (KF_TOKEN_EXPIRATED_AT && shouldUpdateToken) {
      await dispatch('apiRefreshToken');
      dispatch('refetchAllNecessaryRequests');
    } else {
      dispatch('refetchAllNecessaryRequests');
    }
  },
  refetchAllNecessaryRequests({ state, dispatch }) {
    if (!state.userInfo) {
      dispatch('apiFetchUserData');
    }
    if (!state.wsToken) {
      dispatch('apiGetWsToken');
    }
    // dispatch('apiGetOrdersHistory');
    dispatch('balance/apiGetUserBalance', null, { root: true });
  },
  setUserModulePending({ commit }, boolean) {
    commit('SET_USER_MODULE_PENDING', boolean);
  },
  setUserModuleLoaded({ commit }, boolean) {
    commit('SET_USER_MODULE_LOADED', boolean);
  },
  populateHistoryFilter({ state, commit }, data) {
    if (data.field && Object.keys(state.historyFilters).includes(data.field)) {
      commit('POPULATE_HISTORY_FILTER', data);
    }
  },
  populateHistoryDateFilter({ state, commit }, data) {
    if (data.field && Object.keys(state.historyDateFilter).includes(data.field)) {
      commit('POPULATE_HISTORY_DATE_FILTER', data);
    }
  },
  async apiFetchUserData({ commit, dispatch, state }) {
    dispatch('setUserModulePending', true);
    dispatch('setUserModuleLoaded', false);
    try {
      const { data } = await api.get('user/account_info');
      if (!isEmpty(data)) {
        const {
          account_type,
          email,
          is_2fa_enabled,
          is_email_verified,
          name,
          telegram_account,
          deposit_order_processing_rules,
          withdrawal_order_processing_rules,    
          internal_movement_processing_rules,
          exchange_order_limits,
          exchange_order_fees,
          withdrawal_order_limits,
          deposit_order_limits,
          internal_movement_limits,
          withdrawal_order_fees,
          deposit_order_fees,     
          internal_movement_fees,
          account_settings,
          api_keys_list,
          white_ip_list,
          sub_accounts,
          withdrawal_verification_currencies,
          exchange_order_processing_rules,
          invoice_order_processing_rules,
          full_name
        } = data;

        // console.log(internal_movement_processing_rules)
        // console.log(Object.keys(data))

        commit('UPDATE_USER_INFO_DATA', {
          account_type,
          email,
          is_2fa_enabled,
          is_email_verified,
          name,
          full_name,
          telegram_account,
        });
        commit('UPDATE_USER_TOTP_STATUS', is_2fa_enabled);

        commit('SET_USER_DEPOSIT_RULES', deposit_order_processing_rules);
        commit('SET_USER_WITHDRAWAL_RULES', withdrawal_order_processing_rules);
        commit('SET_USER_INTERNAL_MOVEMENT_RULES', internal_movement_processing_rules);

        commit('SET_USER_EXCHANGE_RULES', exchange_order_processing_rules);

        commit('SET_USER_EXCHANGE_LIMITS', exchange_order_limits);
        commit('SET_USER_EXCHANGE_FEES', exchange_order_fees);

        commit('SET_USER_DEPOSIT_LIMITS', deposit_order_limits);
        commit('SET_USER_DEPOSIT_FEES', deposit_order_fees);

        commit('SET_USER_WITHDRAW_LIMITS', withdrawal_order_limits);
        commit('SET_USER_WITHDRAW_FEES', withdrawal_order_fees);

        commit('SET_USER_INTERNAL_MOVEMENT_LIMITS', internal_movement_limits);
        commit('SET_USER_INTERNAL_MOVEMENT_FEES', internal_movement_fees);

        commit('SET_USER_API_SETTINGS', account_settings);

        commit('SET_CURRENCIES_LIST_TO_VERIFY_WITHDRAWAL', withdrawal_verification_currencies);

        commit('SET_INVOICING_PROCESSING_RULES', invoice_order_processing_rules);

        commit('SET_USER_SUB_ACCOUNTS', sub_accounts);
        if (state.userSubAccountId) {
          dispatch('providers/apiGetUserProvidersTransactionsHistory', {}, { root: true });
        }
        //

        commit('SET_USER_API_KEYS', api_keys_list);
        commit('SET_USER_IP_WHITELIST', white_ip_list);
      }
      dispatch('setUserModulePending', false);
      dispatch('setUserModuleLoaded', true);
    } catch (error) {
      dispatch('setUserModulePending', false);
      dispatch('setUserModuleLoaded', true);
      if (error) {
        dispatch('logoutAction');
      }
    }
  },
  apiRefreshToken({ state, commit, dispatch }) {
    if (state.token && state.token.length) {
      return new Promise((resolve, reject) => {
        api
          .post('user/refresh_token', {
            token: state.token,
          })
          .then(response => {
            const {
              data: { token },
            } = response;
            if (token) {
              commit('UPDATE_USER_TOKEN', token);
              const expirationDateTime = new Date().getTime() + TOKEN_EXPIRATION_TIME;
              localStorage.setItem('kf_token_expiration_time', JSON.stringify(expirationDateTime));
              localStorage.setItem('kauri_finance_token', token);
              dispatch('processTokenAutoRefresh');
            }
            resolve();
          })
          .catch(() => {
            commit('TOKEN_REFRESH_TASK', null);
            dispatch('logoutAction');
            reject();
          });
      });
    }
  },
  processTokenAutoRefresh({ commit, dispatch }) {
    const timeUntilRefresh = TOKEN_EXPIRATION_TIME - 30000;
    // dispatch refresh 30 secs before expiration time;
    const refreshTask = setTimeout(() => dispatch('apiRefreshToken'), timeUntilRefresh);
    commit('TOKEN_REFRESH_TASK', refreshTask);
  },
  changeHistoryPage({ commit }, page) {
    commit('CHANGE_HISTORY_PAGE', page);
  },
  // Verify Email //
  verifyUserEmail(context, code) {
    return new Promise((resolve, reject) => {
      api
        .get(`/user/email/verify?code=${code}`)
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
            showAppNotification('success', 'Email address confirmed');
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // Verify Email end//
  // Resend Verify Email Link //
  resendVerifyUserEmail(context, email) {
    return new Promise((resolve, reject) => {
      api
        .post('/user/email/resend_verification', { email })
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
            showAppNotification('success', 'New verification email sent');
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // Resend Verify Email Link end //
  // Reset password //
  resetPasswordSendLink(context, email) {
    console.log(email, 'emailemailemail')
    return new Promise((resolve, reject) => {
      api
        .post('/user/reset_password/send_link', email)
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
            showAppNotification('success', 'Password recovery link has been sent to your email');
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // Reset password end //
  // Reset password confirmation //
  resetPasswordConfirmation(context, password) {
    return new Promise((resolve, reject) => {
      api
        .post('/user/reset_password/set_new_password', password)
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
            showAppNotification('success', 'Password updated successfully');
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // Reset password confirmation end //
  // User Totp section start //
  getGoogleTotpCode() {
    return new Promise((resolve, reject) => {
      api
        .get('user/totp/qr_code')
        .then(response => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  enableGoogleTotp({ commit }, token) {
    return new Promise((resolve, reject) => {
      api
        .post('user/enable_2fa', token)
        .then(response => {
          if (response && response.data) {
            commit('UPDATE_USER_TOTP_STATUS', true);
            showAppNotification('success', 'Google authentication was successfully enabled!');
            if (response.data.token) {
              commit('UPDATE_USER_TOKEN', response.data.token);
              localStorage.setItem('kauri_finance_token', response.data.token);
            }
            resolve(response.data);
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  disableGoogleTotp({ commit }, token) {
    return new Promise((resolve, reject) => {
      api
        .post('user/disable_2fa', token)
        .then(response => {
          if (response && response.data) {
            commit('UPDATE_USER_TOTP_STATUS', false);
            showAppNotification('success', 'Google authentication was successfully disabled!');
            resolve(response.data);
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  verifyGoogleAuthTotp({ commit }, code) {
    return new Promise((resolve, reject) => {
      api
        .post('user/totp/verify', {
          token: code,
        })
        .then(response => {
          const {
            data: { token },
          } = response;
          if (token) {
            commit('UPDATE_USER_TOKEN', token);
            localStorage.setItem('kauri_finance_token', token);
            resolve(token);
          }
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  // User Totp section end //
  // SumSub start //
  async apiUpdateUserSumsubToken(commit, verification_flow) {
    try {
      const {
        data: { token },
      } = await api.post('/user/verification/start', verification_flow);
      return token;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
    }
  },
  // SumSub end //
  async apiGetOrdersHistoryForXLSX( { state, commit } ) {
    try {
      const historyDateFilter = JSON.parse(JSON.stringify(state.historyDateFilter))
      const dateFilterHasAllValues = Object.values(historyDateFilter).every(item => !!item);
      if (dateFilterHasAllValues) {
        historyDateFilter.till_timestamp = moment(historyDateFilter.till_timestamp, 'X').add(1, 'days').format('X');
      }
      const serializedParams = filterAndSerialize({
        ...state.historyFilters,
        ...(dateFilterHasAllValues && historyDateFilter),
      });
      const limit = state.ordersHistoryPageCount * 10

      
      await api.get( `orders/history?${serializedParams}&limit=${limit}` )
        .then( response => {
          commit( "setOrdersHistoryForXLSX", response.data.orders )
        } )
    } catch ( error ) {
      throw new Error( error )
    }
  },
  onChangeVerificationStep({ commit }, step) {
    commit('SET_CHANGE_VERIFICATION_STEP', step)
  },
  updateGeneralFeesLimits({commit}) {
    return new Promise((resolve, reject) => {
      api
        .get('user/general_fees_limits')
        .then(response => {
          commit('SET_GENERAL_FEES_LIMITS', response.data);
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};

export default actions;
