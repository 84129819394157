import auth from '@/router/middleware/auth';

const meta = {
  public: false,
  requiresAuth: true,
  middleware: [auth],
};

const routes = [
  {
    path: '/',
    name: 'account',
    component: () => import('@/views/Application/Application.vue'),
    redirect: '/exchange/dashboard',
    meta,
    children: [
      {
        path: '/exchange',
        name: 'exchange',
        redirect: '/exchange/dashboard',
        component: () => import('@/views/Application/Account/Exchange/Exchange.vue'),
        meta,
        children: [
          {
            path: '/exchange/dashboard',
            name: 'exchange.dashboard',
            component: () => import('@/views/Application/Account/Exchange/Dashboard/Dashboard.vue'),
            meta,
          },
          {
            path: '/exchange/wallet',
            name: 'exchange.wallet',
            redirect: '/exchange/wallet/balance',
            component: () => import('@/views/Application/Account/Exchange/Wallet/Wallet.vue'),
            meta,
            children: [
              {
                path: '/exchange/wallet/balance',
                name: 'exchange.wallet.balance',
                component: () => import('@/views/Application/Account/Exchange/Wallet/Balance/Balance.vue'),
                meta,
              },
              {
                path: '/exchange/wallet/deposit/:currency',
                name: 'exchange.wallet.deposit',
                component: () => import('@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Deposit/Deposit.vue'),
                meta,
              },
              {
                path: '/exchange/wallet/withdraw/:currency',
                name: 'exchange.wallet.withdraw',
                component: () => import('@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/Withdraw.vue'),
                meta,
              },
              {
                path: '/exchange/wallet/fees',
                name: 'exchange.wallet.fees',
                component: () => import('@/views/Application/Account/Exchange/Wallet/Fees/Fees.vue'),
                meta,
                redirect: '/exchange/wallet/fees/all',
                children: [
                  {
                    path: '/exchange/wallet/fees/all',
                    name: 'exchange.wallet.fees.all',
                    component: () => import('@/views/Application/Account/Exchange/Wallet/Fees/All/All.vue'),
                    meta,
                  },
                  {
                    path: '/exchange/wallet/fees/fiat',
                    name: 'exchange.wallet.fees.fiat',
                    component: () => import('@/views/Application/Account/Exchange/Wallet/Fees/Fiat/Fiat.vue'),
                    meta,
                  },
                  {
                    path: '/exchange/wallet/fees/crypto',
                    name: 'exchange.wallet.fees.crypto',
                    component: () => import('@/views/Application/Account/Exchange/Wallet/Fees/Crypto/Crypto.vue'),
                    meta,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/Application/Account/Settings/Settings.vue'),
        meta,
        redirect: '/settings/api',
        children: [
          {
            path: '/settings/user',
            name: 'settings.user',
            component: () => import('@/views/Application/Account/Settings/User/User.vue'),
            meta,
          },
          {
            path: '/settings/security',
            name: 'settings.security',
            component: () => import('@/views/Application/Account/Settings/Security/Security.vue'),
            meta,
          },
          {
            path: '/settings/api',
            name: 'settings.api',
            component: () => import('@/views/Application/Account/Settings/Api/Api.vue'),
            meta,
          },
        ],
      },
      {
        path: '/invoicing',
        name: 'invoicing',
        component: () => import('@/views/Application/Account/Invoicing/Invoicing.vue'),
        meta,
        redirect: '/invoicing/create',
        children: [
          {
            path: '/invoicing/create',
            name: 'invoicing.create',
            component: () => import('@/views/Application/Account/Invoicing/Create/Create.vue'),
            meta,
          },
          {
            path: '/invoicing/pay/:invoice',
            name: 'invoicing.pay',
            component: () => import('@/views/Application/Account/Invoicing/Pay/Pay.vue'),
            meta,
          },
        ],
      },
      {
        path: '/fund',
        name: 'fund',
        component: () => import('@/views/Application/Account/Fund/Fund.vue'),
        meta,
      },
      {
        path: '/verification',
        name: 'verification',
        component: () => import('@/views/Application/Account/Verification/Verification.vue'),
        meta,
      },
      {
        path: '/providers',
        name: 'providers',
        component: () => import('@/views/Application/Account/Providers/Providers.vue'),
        meta,
        redirect: '/providers/dashboard',
        children: [
          {
            path: '/providers/dashboard',
            name: 'providers.dashboard',
            component: () => import('@/views/Application/Account/Providers/Dashboard/Dashboard.vue'),
            meta,
          },
          {
            path: '/providers/provider/:providerId?',
            name: 'providers.provider',
            component: () => import('@/views/Application/Account/Providers/Dashboard/CreateAndUpdateProvider/CreateAndUpdateProvider.vue'),
            meta,
          },
          {
            path: '/providers/:providerId/route/:routeId?',
            name: 'providers.provider.route',
            component: () => import('@/views/Application/Account/Providers/Dashboard/CreateAndUpdateProvider/CreateAndUpdateRouter.vue'),
            meta,
          },
          {
            path: '/providers/dashboard/details/:id',
            name: 'providers.dashboard.details',
            component: () => import('@/views/Application/Account/Providers/Dashboard/Details/Details.vue'),
            meta,
          },
          {
            path: '/providers/balances',
            name: 'providers.balances',
            component: () => import('@/views/Application/Account/Providers/Balances/Balances.vue'),
            meta,
          },
          {
            path: '/providers/limits',
            name: 'providers.limits',
            component: () => import('@/views/Application/Account/Providers/Limits/Limits.vue'),
            meta,
          },
          {
            path: '/providers/history',
            name: 'providers.history',
            component: () => import('@/views/Application/Account/Providers/History/History.vue'),
            meta,
          },

        ],
      },
      {
        path: "/404",
        name: 'PageNotFound',
        component: () => import('@/views/Application/Account/PageNotFound/PageNotFound.vue'),
      },
      {
        path: "*",
        redirect: '/404'
      },
    ],
  },
  // {
  //   path: '/status-success',
  //   component: () => import('@/views/PaymentStatusSuccess.vue'),
  //   meta,
  // },
  // {
  //   path: '/status-fail',
  //   component: () => import('@/views/PaymentStatusFail.vue'),
  //   meta,
  // },
];
// TODO:
// meta auth should work for children too, so it redirects to /auth path after bad request with expired token
export default routes.map(route => {
  return {
    ...route,
    meta,
  };
});
