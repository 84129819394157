const getDefaultState = () => {
  return {
    depositAndWithdrawalCashboxForm: {
      contact_name: '',
      city: '',
      phone_number: '',
      amount: '',
    },
    depositAndWithdrawalCashboxBreinrockForm: {
      username: '',
      account_number: '',
      amount: '',
      comment: '',
    },
    withdrawCryptoForm: {
      wallet_to: '',
      amount: '',
      comment: '',
      currency: '',
      withdrawal_type: 'GATEWAY',
      payment_method: '',
    },
    withdrawFiatGatewayForm: {
      wallet_to: '',
      amount: '',
      comment: '',
      currency: '',
      withdrawal_type: 'GATEWAY',
    },
    withdrawSepaForm: {
      step: 1,
      amount: 0,
      currency: '',
      withdrawal_type: "SEPA",
      additional_info: {
        bank_details: {
          name: '',
          address: '',
          location: '',
          country: '',
          bic: '',
          aba: '',
        },
        customer_details: {
          name: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          country: '',
          iban: '',
          ref_message: '',
          description: '',
        },
      },
    },
    withdrawSepaFormVerified: {
      step: 1,
      amount: 0,
      withdrawal_type: "SEPA",
      additional_info: {
        customer_details: {
          name: '',
          iban: '',
        },
      },
    },
    withdrawSwiftForm: {
      step: 1,
      amount: 0,
      currency: '',
      withdrawal_type: "SEPA",
      additional_info: {
        bank_details: {
          name: '',
          address: '',
          location: '',
          country: '',
          bic: '',
          aba: '',
        },
        customer_details: {
          name: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          region: '',
          country: '',
          iban: '',
          ref_message: '',
          description: '',
        },
      },
    },
    internalMovementForm: {
      comment: '',  
      amount: '',
      currency: '',
      destination_account_email: '',
    },
    depositPbtPay: {
      step: 1,
      showComment: false,
      holder_name: "",
      number: "",
      expire_card_date: "",
      cv2: "",
      comment: "",
      amount: "",
      country: "",
      city: "",
      address_line1: "",
      postal_code: "",
      isLoading: false,
    },
  };
};

const state = getDefaultState();

export { getDefaultState, state };
