<template functional>
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.859877 3.70806L3.45041 1.11754C3.61754 0.950405 3.88495 0.950405 4.03536 1.11754C4.18578 1.28467 4.20249 1.55208 4.03536 1.70249L2.14678 3.59107H12.0744C12.3083 3.59107 12.4922 3.77492 12.4922 4.0089C12.4922 4.24288 12.3083 4.42673 12.0744 4.42673H2.1635L4.01865 6.29859C4.18578 6.46572 4.18578 6.73313 4.01865 6.88355C3.93509 6.96712 3.83481 7.00054 3.71782 7.00054C3.60082 7.00054 3.50055 6.96712 3.41698 6.88355L0.843164 4.30974C0.692746 4.14261 0.692746 3.8752 0.859877 3.70806Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.6"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>
