import { api } from '@/axios/index';
import { socketPublic } from '@/socket';
import {
  modifyDataForMarketCalculateRequest,
  modifyDataForLimitCalculateRequest,
  modifyCalculatedRequestData,
} from './_helpers/exchangeCalculateHelper';
import { showAppNotification } from '@/utils/appNotification';
import { getCurrenciesListToHide } from "@/utils/helpers"

const actions = {
  updateExchangeMarketObject({ commit }, payload) {
    commit('UPDATE_MARKET_EXCHANGE_OBJECT', payload);
  },
  updateExchangeLimitObject({ commit }, payload) {
    commit('UPDATE_LIMIT_EXCHANGE_OBJECT', payload);
  },
  updateCurrencyBasePriceBySocketAnswer({ commit, state, dispatch }, payload) {
    let rates = state.exchangeRates
    rates.forEach((rate, index)=>{
      if (rate.pair == payload.pair) {
        rates[index] = payload
      }
    })
    commit('UPDATE_EXCHANGE_RATES', rates);
    dispatch('setExchangeRate', { updateLimitExchange: false });
  },
  stopListeningToCoinsPairRateAndChangeExchangeActiveTab({ commit, dispatch }, tab) {
    commit('CHANGE_EXCHANGE_ACTIVE_TAB', tab);
  },
  startListenToCoinPairsExchangeRate({ state }) {
    socketPublic.send(
      JSON.stringify({
        event: 'SUBSCRIBE',
        channel: 'ticker',
      }),
    );
  },
  setExchangeRate({ commit, state }, options = {}) {
    const { currency_to_get: currencyToGetMarket , currency_to_spend: currencyToSpendMarket } = state.exchangeMarketObject;
    const rates = state.exchangeRates;
    const currentExchangeMarketPair = rates.find(el => el.pair === `${currencyToGetMarket}_${currencyToSpendMarket}`);
    const { currency_to_get: currencyToGetLimit, currency_to_spend: currencyToSpendLimit } = state.exchangeLimitObject;
    const currentExchangeLimitPair = rates.find(el => el.pair === `${currencyToGetLimit}_${currencyToSpendLimit}`);
    if (currentExchangeMarketPair) {
      commit('UPDATE_MARKET_EXCHANGE_OBJECT', {
        field: 'exchange_price',
        value: currentExchangeMarketPair.price,
      });
    }
    // console.log(options.updateLimitExchange)
    if (currentExchangeLimitPair && options.updateLimitExchange != false) {
      commit('UPDATE_LIMIT_EXCHANGE_OBJECT', {
        field: 'exchange_price',
        value: currentExchangeLimitPair.price,
      });
    }
  },
  async apiGetExchangeRate({ commit, dispatch }) {
    try {
      const {
        data: { rates },
      } = await api.get('exchange_rate');
      commit('UPDATE_EXCHANGE_RATES', rates);
      dispatch('setExchangeRate');
    } catch (error) {
      console.error('apiGetExchangeRate error', error.response.data.detail);
    }
  },
  async apiGetListOfCurrencies({ commit }) {
    try {
      const {
        data: { currencies },
      } = await api.get('currency');
      commit('UPDATE_LIST_OF_CURRENCIES', currencies.filter(currency => getCurrenciesListToHide().every(el=>el !== currency) ));
    } catch (error) {
      console.error('apiGetListOfCurrencies error', error.response.data.detail);
    }
  },
  async apiGetMarketExchangeCalculated({ commit }, field) {
    const amount = modifyDataForMarketCalculateRequest(field);
    if (amount.currency_to_get_amount || amount.currency_to_spend_amount) {
      try {
        const { data } = await api.get('exchange/calculate', {
          params: modifyDataForMarketCalculateRequest(field),
        });
        const modifiedCalculatedData = modifyCalculatedRequestData(data, field);
        commit('UPDATE_MARKET_EXCHANGE_OBJECT_WITH_CALCULATED_API_REQUEST',modifiedCalculatedData);
      } catch (error) {
        console.error('apiGetMarketExchangeCalculated error', error.response.data.detail);
      }
    }
  },
  async apiGetLimitExchangeCalculated({ commit }, field) {
    const amount = modifyDataForLimitCalculateRequest(field);
    if (amount.currency_to_get_amount || amount.currency_to_spend_amount) {
      try {
        const { data } = await api.get('exchange/calculate', {
          params: modifyDataForLimitCalculateRequest(field),
        });
        const modifiedCalculatedData = modifyCalculatedRequestData(data, field);
        commit('UPDATE_LIMIT_EXCHANGE_OBJECT_WITH_CALCULATED_API_REQUEST', modifiedCalculatedData);
      } catch (error) {
        console.error('apiGetLimitExchangeCalculated error', error.response.data.detail);
      }
    }
  },
  async apiPostExchangeRequest({ state, dispatch }, type) {
    const payload = type === 'market' ? state.exchangeMarketObject : state.exchangeLimitObject;
    const { currency_to_get, currency_to_spend, exchange_price, last_updated_field } = payload;
    const requestData = {
      currency_to_get,
      currency_to_spend,
      ...{
        [last_updated_field]: payload[payload.last_updated_field],
      },
    };
    if (type === 'limit') {
      requestData.exchange_price = exchange_price;
    }
    try {
      const data = await api.post('exchange', requestData);
      showAppNotification('success', 'Exchange order successfully created');
      dispatch('user/fetchUserNecessaryData', null, { root: true });
      return data;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
    }
  },
};

export default actions;
