import store from '@/store';

const SOCKET_PUBLIC_URL = 'wss://api.kauri.finance:8080/public/';
const SOCKET_PRIVATE_URL = 'wss://api.kauri.finance:8080/private/';
// staging socket url
// const SOCKET_PRIVATE_URL = 'wss://api.staging.kauri.finance:8001/private/';

let socketPrivate
const socketPublic = new WebSocket(SOCKET_PUBLIC_URL);

// store.dispatch('balance/apiGetUserBalance'); 
// store.dispatch('user/apiGetOrdersHistory');

function connect() {
  // console.log('connect socket')
  socketPrivate = new WebSocket(SOCKET_PRIVATE_URL);
  socketPrivate.onclose = function (e) {
    // console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    // console.log(e)
    setTimeout(function () {
      connect();
    }, 10000);
  };
  socketPrivate.onmessage = msg => {
    const { data } = msg;
    const parsedData = JSON.parse(data);
    if (parsedData?.message) {
      const {reserved, total, currency, dt, order_type, status, order_sub_type} = parsedData.message
      if (reserved && total && currency) {
        store.dispatch('balance/apiGetUserBalance');
      }
      if (dt && order_type && status && order_sub_type) {
        store.dispatch('user/apiGetOrdersHistory');
      }
    }
  };
  socketPrivate.onopen = () => {
    // const { wsToken } = store.state.user
    // console.log(store.state.user)
    // console.log(wsToken)
    // store.watch(
    //   (state, getters) => getters.status,
    //   (newValue, oldValue) => {
    //     console.log(`Updating from ${oldValue} to ${newValue}`);

        // Do whatever makes sense now
        // if (newValue === 'success') {
        //   this.complex = {
        //     deep: 'some deep object',
        //   };
        // }
    //   },
    // )
    // socketPrivate.send(
    //   JSON.stringify({
    //     event: 'SUBSCRIBE',
    //     channel: 'balance',
    //     token: wsToken,
    //   }),
    // );
    // socketPrivate.send(
    //   JSON.stringify({
    //     event: 'SUBSCRIBE',
    //     channel: 'orders',
    //     token: wsToken,
    //   }),
    // );
  }
}
connect();

export { socketPrivate, socketPublic };

