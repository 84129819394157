export const isCrypto = (currency = 'BTC') => {
  currency = currency.toUpperCase()
  switch (currency) {
    case 'BTC':
    case 'ETH':
    case 'USDT':
    case 'DOGE':
    case 'LTC':
    case 'BNB':
    case 'TRX':
    case 'ADA':
    case 'USDTOMNI':
    case 'TRON':
    case 'DOT':
    case 'MATIC':
    case 'XRP':
    case 'SOL':
    case 'SHIB':
    case 'UACB-NOV22':
    case 'USDC':
    case 'BRNC':
      return true;
    default:
      return false;
  }
};
