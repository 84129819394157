<template functional>
  <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.69491 31.9813C3.93519 31.9563 0 22.237 0 17.2899C0 9.20713 6.0652 7.43942 8.40133 7.43942C9.45696 7.43942 10.5813 7.85168 11.5682 8.22021C12.2616 8.47631 12.9799 8.73866 13.3797 8.73866C13.617 8.73866 14.1792 8.51379 14.6789 8.32015C15.7408 7.8954 17.0588 7.37695 18.5954 7.37695H18.6016C19.7509 7.37695 23.2239 7.62681 25.3164 10.7687L25.8036 11.5058L25.0978 12.0367C24.0921 12.7988 22.2557 14.1792 22.2557 16.9276C22.2557 20.1757 24.3357 21.425 25.3352 22.0309C25.7786 22.2932 26.2346 22.5681 26.2346 23.1677C26.2346 23.5612 23.1177 31.9501 18.5891 31.9501C17.4835 31.9501 16.6965 31.619 16.0094 31.3254C15.3098 31.0256 14.7101 30.7695 13.7107 30.7695C13.2048 30.7695 12.5676 31.0069 11.893 31.263C10.9686 31.6065 9.92543 32 8.73863 32H8.69491V31.9813Z"
      :fill="props.fillColor"
    />
    <path d="M19.07 0C19.1887 4.25376 16.1467 7.20203 13.111 7.01464C12.6113 3.62288 16.1467 0 19.07 0Z" :fill="props.fillColor" />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>
