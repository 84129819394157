const state = () => ({
  userProviders: {},
  providersBalances: {},
  providersTransactionHistory: {},
  providersHistoryFilters: {
    page: 1,
    currency: '',
    tr_type: '',
    tr_status: '',
    search: '',
    provider: '',
    limit: 10,
  },
  providersHistoryDateFilter: {
    date_from: '',
    date_to: '',
    date_type: 'transaction_date',
  },
  providersHistoryPageCount: 1,
  providersFee: {},
  supportedProviderIntegrations: [],
  activateProviderForm: {
    name: '',
    provider: '',
    withdrawal: false,
    deposit: false,
  },
});

export default state;
