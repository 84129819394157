import { DEFAULT_CURRENCY_TO_GET, DEFAULT_CURRENCY_TO_SPEND } from './constants';
// const userToken = localStorage.getItem('kauri_finance_token');

const exchangeMarketObject = {
  currency_to_get: DEFAULT_CURRENCY_TO_GET,
  currency_to_spend: DEFAULT_CURRENCY_TO_SPEND,
  currency_to_get_amount: '',
  currency_to_spend_amount: '',
  exchange_price: '',
  last_updated_field: '',
  fee: '0',
};

const exchangeLimitObject = {
  currency_to_get: DEFAULT_CURRENCY_TO_GET,
  currency_to_spend: DEFAULT_CURRENCY_TO_SPEND,
  currency_to_get_amount: '',
  currency_to_spend_amount: '',
  exchange_price: '',
  last_updated_field: '',
};

const state = () => ({
  currencies: [],
  exchangeRates: [],
  exchangeMarketObject,
  exchangeLimitObject,
  activeExchangeTab: 'market',
});

export default state;
