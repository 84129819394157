<template functional>
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99235 5.68979C9.4539 5.68979 10.6078 4.53593 10.6078 3.07439C10.6078 1.61284 9.37698 0.458984 7.99235 0.458984C6.60773 0.458984 5.37695 1.61284 5.37695 3.07439C5.37695 4.53593 6.53081 5.68979 7.99235 5.68979ZM5.99234 3.07439C5.99234 1.99746 6.91543 1.07437 7.99235 1.07437C9.06928 1.07437 9.99237 1.99746 9.99237 3.07439C9.99237 4.15132 9.06928 5.0744 7.99235 5.0744C6.91543 5.0744 5.99234 4.15132 5.99234 3.07439Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.2"
    />
    <path
      d="M12.6078 5.61224C12.2231 5.22762 11.5308 5.30455 11.2231 5.68917L9.91543 6.99687C9.76159 6.7661 9.45389 6.68917 9.22312 6.68917H6.60772C6.30003 6.61225 5.83848 6.53533 5.45387 6.53533C4.30001 6.53533 3.30001 7.07379 2.83847 7.99687C2.60769 7.99687 2.37692 8.0738 2.22308 8.22764L1.14615 9.30457C0.9923 9.45842 0.9923 9.61227 1.14615 9.76612L3.76155 12.3815C3.83847 12.4584 3.9154 12.4584 3.99232 12.4584C4.06924 12.4584 4.14617 12.4584 4.22309 12.3815L5.37694 11.3046C5.60771 11.0738 5.68464 10.843 5.60771 10.5354V10.3046C5.76156 10.2277 5.83848 10.1507 6.06926 10.1507H9.1462C9.68466 10.1507 10.2231 9.91996 10.6847 9.53535C10.6847 9.53535 10.6847 9.53535 11.1462 8.99688L12.8385 6.99687C13.1462 6.61225 13.0693 5.99686 12.6078 5.61224ZM4.83848 10.843L3.9154 11.7661L1.76154 9.53535L2.60769 8.61226C2.68462 8.53534 2.76154 8.53534 2.83847 8.61226C2.83847 8.61226 2.91539 8.68919 3.22308 8.99688C3.53078 9.30457 3.99232 9.76612 4.83848 10.6123C4.83848 10.6892 4.83848 10.7661 4.83848 10.843ZM5.45387 7.15071C5.76156 7.15071 6.14618 7.22764 6.45387 7.38148C6.5308 7.38148 6.5308 7.38148 6.60772 7.38148H9.22312C9.45389 7.38148 9.60774 7.53533 9.60774 7.7661C9.60774 7.99687 9.45389 8.15072 9.22312 8.15072H7.30003C7.14618 8.15072 6.99234 8.30457 6.99234 8.45841C6.99234 8.61226 7.14618 8.76611 7.30003 8.76611H9.22312C9.76159 8.76611 10.2231 8.30457 10.2231 7.7661V7.68918L11.2231 6.68917C11.6847 6.15071 11.7616 6.15071 11.7616 6.15071C11.9154 5.99686 12.1462 5.99686 12.3001 6.07378C12.4539 6.22763 12.4539 6.4584 12.377 6.61225L10.2231 9.0738C9.91543 9.3815 9.53082 9.53535 9.1462 9.53535H6.06926C5.76156 9.53535 5.45387 9.68919 5.2231 9.91996L5.06925 10.0738L3.37693 8.38149C3.76155 7.53533 4.53078 7.15071 5.45387 7.15071Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
