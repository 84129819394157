<template functional>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_529:187)">
      <path
        d="M14 3.34783C14 3.30978 13.9924 3.27174 13.9772 3.2337C13.9696 3.21848 13.962 3.20707 13.9543 3.19565C13.9429 3.17663 13.9353 3.15761 13.9239 3.14239L11.1848 0.098913C11.1239 0.0380435 11.044 0 10.9565 0H10.363C10.3554 0 10.344 0 10.3364 0H5.78261C5.28043 0 4.86957 0.41087 4.86957 0.913043V2.43478H0.913043C0.41087 2.43478 0 2.84565 0 3.34783V13.087C0 13.5891 0.41087 14 0.913043 14H8.21739C8.71956 14 9.13043 13.5891 9.13043 13.087V11.5652H13.087C13.5891 11.5652 14 11.1543 14 10.6522V3.95652V3.34783ZM8.52174 13.087C8.52174 13.2543 8.38478 13.3913 8.21739 13.3913H0.913043C0.745652 13.3913 0.608696 13.2543 0.608696 13.087V3.34783C0.608696 3.18043 0.745652 3.04348 0.913043 3.04348H4.86957V6.3913C4.86957 6.5587 5.00652 6.69565 5.17391 6.69565H8.52174V13.087ZM8.52174 6.08696H5.47826V3.04348H5.65706L8.52174 5.90815V6.08696ZM13.3913 10.6522C13.3913 10.8196 13.2543 10.9565 13.087 10.9565H9.13043V6.40652C9.13043 6.40272 9.13043 6.39891 9.13043 6.3913V5.78261C9.13043 5.70272 9.1 5.63043 9.04674 5.57337L9.04293 5.56956L5.99946 2.52609C5.94239 2.46902 5.8663 2.43478 5.78261 2.43478H5.47826V0.913043C5.47826 0.745652 5.61522 0.608696 5.78261 0.608696H10.0435V3.95652C10.0435 4.12391 10.1804 4.26087 10.3478 4.26087H13.3913V10.6522ZM13.3913 3.65217H10.6522V0.608696H10.8196L13.3913 3.46576V3.65217Z"
        :fill="props.fillColor"
        :stroke="props.fillColor"
        stroke-width="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_529:187">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>
