<template functional>
  <svg width="19" height="17" viewBox="0 0 19 17" :fill="props.fillColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.952274 10.1922L2.8081 11.6335C3.05688 11.8327 3.37117 11.897 3.65561 11.8208C3.94006 11.7445 4.18007 11.5317 4.29597 11.2349L5.18247 9.0587C5.30032 8.76916 5.15741 8.44005 4.86787 8.3222C4.57833 8.20436 4.24922 8.34726 4.13137 8.6368L3.65127 9.82075C3.00653 6.33511 5.14248 2.87046 8.62145 1.93827C12.2755 0.959182 16.0485 3.13756 17.0276 6.79158C18.0087 10.4529 15.8303 14.226 12.1763 15.2051C11.4251 15.4063 10.6479 15.4817 9.88128 15.4213C9.56948 15.3954 9.2969 15.6326 9.27101 15.9444C9.24511 16.2562 9.48231 16.5288 9.79411 16.5547C10.6945 16.6183 11.5942 16.5336 12.4694 16.2991C16.7288 15.1578 19.2649 10.7651 18.1236 6.50573C16.9823 2.24636 12.5896 -0.289747 8.33026 0.851549C4.2897 1.93422 1.80031 5.94696 2.53132 9.98795L1.6516 9.30125C1.50507 9.19199 1.32312 9.15476 1.15537 9.19971C1.03867 9.23097 0.93175 9.29871 0.856478 9.39705C0.657357 9.64583 0.705448 10.0004 0.952274 10.1922Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>
