<template functional>
  <svg width="16"
       height="15"
       viewBox="0 0 16 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6494 8.24314C5.0996 8.24314 5.53386 8.21525 5.94422 8.15947V3.65349C5.53386 3.59771 5.0996 3.56982 4.6494 3.56982C2.07968 3.56982 0 4.51405 0 5.68138V6.13556C0 7.3029 2.07968 8.24314 4.6494 8.24314Z"
      fill="white"
    />
    <path
      d="M4.6494 10.5976C5.0996 10.5976 5.53386 10.5697 5.94422 10.514V9.19127C5.53386 9.24705 5.0996 9.27494 4.6494 9.27494C2.41036 9.27494 0.537849 8.55382 0.0996016 7.59766C0.0358566 7.7371 0 7.88052 0 8.02793V8.4861C0 9.65342 2.07968 10.5976 4.6494 10.5976Z"
      fill="white"
    />
    <path
      d="M5.94422 11.6494V11.5458C5.53386 11.6016 5.0996 11.6294 4.6494 11.6294C2.41036 11.6294 0.537849 10.9083 0.0996016 9.95215C0.0318725 10.0956 0 10.239 0 10.3864V10.8446C0 12.0079 2.07968 12.9522 4.6494 12.9522C5.16335 12.9522 5.65737 12.9123 6.11952 12.8446C6.00398 12.6095 5.94422 12.3625 5.94422 12.1075V11.6494Z"
      fill="white"
    />
    <path
      d="M11.3506 0C8.7849 0 6.70123 0.944223 6.70123 2.10757V2.56574C6.70123 3.72908 8.78092 4.67331 11.3506 4.67331C13.9164 4.67331 16 3.72908 16 2.56574V2.10757C16 0.944223 13.9204 0 11.3506 0Z"
      fill="white"
    />
    <path
      d="M11.3506 5.70513C9.11159 5.70513 7.23908 4.98401 6.80083 4.02783C6.73709 4.16727 6.70123 4.3107 6.70123 4.45811V4.91628C6.70123 6.07963 8.78092 7.02385 11.3506 7.02385C13.9164 7.02385 16 6.07963 16 4.91628V4.4621C16 4.31469 15.9682 4.17126 15.9004 4.03182C15.4622 4.98799 13.5937 5.70513 11.3506 5.70513Z"
      fill="white"
    />
    <path
      d="M11.3506 8.05986C9.11159 8.05986 7.23908 7.33875 6.80083 6.38257C6.73709 6.52201 6.70123 6.66544 6.70123 6.81285V7.27102C6.70123 8.43437 8.78092 9.37859 11.3506 9.37859C13.9164 9.37859 16 8.43437 16 7.27102V6.81285C16 6.66544 15.9682 6.52201 15.9004 6.38257C15.4622 7.34273 13.5937 8.05986 11.3506 8.05986Z"
      fill="white"
    />
    <path
      d="M11.3506 10.5418C9.11159 10.5418 7.23908 9.82068 6.80083 8.8645C6.73709 9.00394 6.70123 9.14737 6.70123 9.29478V9.75295C6.70123 10.9163 8.78092 11.8605 11.3506 11.8605C13.9164 11.8605 16 10.9163 16 9.75295V9.29478C16 9.14737 15.9682 9.00394 15.9004 8.8645C15.4622 9.82068 13.5937 10.5418 11.3506 10.5418Z"
      fill="white"
    />
    <path
      d="M11.3506 12.8965C9.11159 12.8965 7.23908 12.1754 6.80083 11.2192C6.73709 11.3587 6.70123 11.5021 6.70123 11.6495V12.1077C6.70123 13.271 8.78092 14.2153 11.3506 14.2153C13.9164 14.2153 16 13.271 16 12.1077V11.6495C16 11.5021 15.9682 11.3587 15.9004 11.2192C15.4622 12.1754 13.5937 12.8965 11.3506 12.8965Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCoins',
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
