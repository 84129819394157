import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';

const actions = {
  setUserBalancesModulePending({ commit }, boolean) {
    commit('SET_USER_BALANCES_MODULE_PENDING', boolean);
  },
  setUserBalancesModuleLoaded({ commit }, boolean) {
    commit('SET_USER_BALANCES_MODULE_LOADED', boolean);
  },
  async apiGetUserBalance({ commit, dispatch }) {
    dispatch('setUserBalancesModulePending', true);
    try {
      const {
        data: { wallets, balance, acquiring_balance, trading_balance },
      } = await api.get('user/balance');
      commit('UPDATE_USER_BALANCE', balance);
      commit('UPDATE_USER_WALLETS', wallets);
      commit('UPDATE_USER_TRADING_BALANCE', trading_balance);
      dispatch('providers/updateUserProvidersBalances', acquiring_balance, { root: true });
      dispatch('setUserBalancesModulePending', false);
      dispatch('setUserBalancesModuleLoaded', true);
    } catch (error) {
      dispatch('setUserBalancesModulePending', false);
      dispatch('setUserBalancesModuleLoaded', true);
      showAppNotification('error', error.response.data.detail);
      dispatch('user/logoutAction', null, { root: true });
    }
  },
};

export default actions;
