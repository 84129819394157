const state = () => ({
  invoice: {},
  invoice_payment_status: false,
  invoiceGatewayPay: {
    step: 1,
    showComment: false,
    holder_name: "",
    number: "",
    expire_card_date: "",
    cv2: "",
    comment: "",
    amount: "",
    country: "",
    city: "",
    address_line1: "",
    postal_code: "",
    isLoading: false,
    currency: "",
    order_id: "",
  }
});

export default state;
