const mutations = {
  UPDATE_USER_BALANCE(state, payload) {
    state.balance = Object.assign({}, payload);
  },
  UPDATE_USER_WALLETS(state, payload) {
    state.userWallets = Object.assign({}, payload);
  },
  UPDATE_USER_TRADING_BALANCE(state, payload) {
    state.userTradingBalance = Object.assign({}, payload);
  },
  SET_USER_BALANCES_MODULE_PENDING(state, payload) {
    state._userBalancesModulePending = payload;
  },
  SET_USER_BALANCES_MODULE_LOADED(state, payload) {
    state._userBalancesModuleLoaded = payload;
  },
};

export default mutations;
