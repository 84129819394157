const mutations = {
  SET_FUNDS(state, payload) {
    // Temporary set single Fund
    state.funds = payload.data[0];
  },
  SET_FUNDS_LOADED(state, payload) {
    state.fundsIsLoaded = payload;
  },
};

export default mutations;
