import { render, staticRenderFns } from "./iconCoins.vue?vue&type=template&id=5ea4e808&functional=true&"
import script from "./iconCoins.vue?vue&type=script&lang=js&"
export * from "./iconCoins.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports