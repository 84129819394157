import { isEmpty } from 'ramda';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

const formattedOrderSubType = {
  CASHBOX: 'cashbox',
  MARKET_EXCHANGE: '(market)',
  LIMIT_EXCHANGE: '(limit)',
  SEPA: 'Sepa',
  SWIFT: 'Swift',
  GATEWAY: 'Gateway',
};

export const formatOrderTypeToHistoryView = order => {
  if (isEmpty(order)) return '-';
  const { order_type, order_sub_type, currency } = order;
  const formattedOrderType = (order_type && order_type.toLowerCase()) || order_type;
  if (order_type === 'INVOICE') {
    return `${formattedOrderType} ${currency}`;
  }
  if (order_type === 'INTERNAL_MOVEMENT') {
    return 'Internal Movement'
  }
  if (order_sub_type == 'GATEWAY') {
    if (order_type == 'DEPOSIT') {
      const formattedOrderCurrency = isCrypto(currency) ? currency : 'Visa/<nobr>MasterCard</nobr>'
      return `${formattedOrderType} ${formattedOrderCurrency}`
    }
    if (order_type == 'WITHDRAWAL' && isCrypto(currency)) {
      const formattedOrderCurrency = currency
      return `${formattedOrderType} ${formattedOrderCurrency}`
    }
  }
  if (order_sub_type == 'CASHBOX') {
    const orderTypeCapitilized = order_type.charAt(0).toUpperCase() + order_type.slice(1).toLowerCase()
    return `Breinrock ${orderTypeCapitilized}`
  }
  const orderSubTypeToView = formattedOrderSubType[order_sub_type] || order_sub_type;
  // console.log(`${formattedOrderType} ${orderSubTypeToView}`)
  // console.log(order)
  return `${formattedOrderType} ${orderSubTypeToView}`;
};