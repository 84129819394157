import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../assets/stylus/main.styl';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      dark: {
        // primary: '#0f1f3f',
        error: '#ff556d',
      },
    },
    dark: true,
  },
});
