<template functional>
  <svg width="9" height="9" viewBox="0 0 9 9" :fill="props.fillColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.02063 4.24285H4.8546V1.07682C4.8546 0.872288 4.67918 0.696875 4.47465 0.696875H4.26936C4.17117 0.696875 4.08105 0.745122 4.01739 0.811811C3.95319 0.879072 3.90808 0.97299 3.90808 1.07682V4.24285H0.742043C0.53751 4.24285 0.362097 4.41827 0.362097 4.6228V4.82809C0.362097 4.92628 0.410344 5.0164 0.477033 5.08006C0.544294 5.14427 0.638212 5.18938 0.742043 5.18938H3.90808V8.35541C3.90808 8.55994 4.08349 8.73536 4.28802 8.73536H4.49332C4.69785 8.73536 4.87326 8.55994 4.87326 8.35541V5.18938H8.03929C8.24383 5.18938 8.41924 5.01396 8.41924 4.80943V4.60414V4.59703L8.41823 4.59C8.40513 4.49826 8.35437 4.41276 8.28635 4.35066C8.21788 4.28814 8.12473 4.24285 8.02063 4.24285Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
