const mutations = {
  UPDATE_LIST_OF_CURRENCIES(state, payload) {
    state.currencies = payload;
  },
  UPDATE_EXCHANGE_RATES(state, payload) {
    state.exchangeRates = payload;
  },
  UPDATE_MARKET_EXCHANGE_OBJECT(state, payload) {
    state.exchangeMarketObject[payload.field] = payload.value;
    if (payload.field === 'currency_to_get_amount' || payload.field === 'currency_to_spend_amount') {
      state.exchangeMarketObject.last_updated_field = payload.field;
    }
  },
  UPDATE_LIMIT_EXCHANGE_OBJECT(state, payload) {
    state.exchangeLimitObject[payload.field] = payload.value;
    if (payload.field === 'currency_to_get_amount' || payload.field === 'currency_to_spend_amount') {
      state.exchangeLimitObject.last_updated_field = payload.field;
    }
  },
  UPDATE_MARKET_EXCHANGE_OBJECT_WITH_CALCULATED_API_REQUEST(state, payload) {
    state.exchangeMarketObject = Object.assign(state.exchangeMarketObject, payload);
  },
  UPDATE_LIMIT_EXCHANGE_OBJECT_WITH_CALCULATED_API_REQUEST(state, payload) {
    const {exchange_price, ...propsWithoutExchangePrice} = payload
    state.exchangeLimitObject = Object.assign(state.exchangeLimitObject, propsWithoutExchangePrice);
    // console.log(state.exchangeLimitObject)
    if (state.exchangeLimitObject.exchange_price === '') {
      // state.exchangeLimitObject.exchange_price = exchange_price;
    }
  },
  CHANGE_EXCHANGE_ACTIVE_TAB(state, payload) {
    state.activeExchangeTab = payload;
  },
};

export default mutations;
