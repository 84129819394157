import { omit } from 'ramda';
import store from '@/store';

export const modifyDataForMarketCalculateRequest = field => {
  const {
    state: {
      exchange: { exchangeMarketObject },
    },
  } = store;
  const { currency_to_get, currency_to_get_amount, currency_to_spend, currency_to_spend_amount } = exchangeMarketObject;
  if (field === 'currency_to_get_amount' || field === 'currency_to_spend_amount') {
    return {
      currency_to_get,
      currency_to_spend,
      [field]: exchangeMarketObject[field],
    };
  }
  if (field === 'currency_to_get') {
    return {
      currency_to_get_amount,
      currency_to_get,
      currency_to_spend,
    };
  }
  return {
    currency_to_spend_amount,
    currency_to_spend,
    currency_to_get,
  };
};

export const modifyDataForLimitCalculateRequest = field => {
  const {
    state: {
      exchange: { exchangeLimitObject },
    },
  } = store;
  const { currency_to_get, currency_to_get_amount, currency_to_spend, currency_to_spend_amount, exchange_price, last_updated_field } = exchangeLimitObject;
  if (field === 'currency_to_get_amount' || field === 'currency_to_spend_amount') {
    return {
      currency_to_get,
      currency_to_spend,
      [field]: exchangeLimitObject[field],
      exchange_price,
    };
  }
  if (field === 'currency_to_get') {
    return {
      currency_to_get_amount,
      currency_to_get,
      currency_to_spend,
    };
  }
  if (field === 'exchange_price') {
    // console.log(state.exchangeLimitObject.last_updated_field)
    // console.log(last_updated_field)    
    return {
      exchange_price,
      // currency_to_spend_amount,
      // currency_to_get_amount,
      [last_updated_field]: exchangeLimitObject[last_updated_field],
      currency_to_spend,
      currency_to_get,
    };
  }
  return {
    currency_to_spend_amount,
    currency_to_spend,
    currency_to_get,
  };
};

export const modifyCalculatedRequestData = (payload, field) => {
  let omittedPayload = omit(['status'], payload);
  if (field === 'currency_to_get_amount' || field === 'currency_to_spend_amount') {
    const clearPayload = omit([`${field}`, 'status'], omittedPayload);
    return clearPayload;
  }
  return omittedPayload;
};
