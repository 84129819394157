import { getDefaultState } from './state';
import { flattenObj } from '@/utils/helpers.js'
import pay from "../../views/Application/Account/Invoicing/Pay/Pay";

const mutations = {
  UPDATE_USER_INFO_DATA(state, payload) {
    state.userInfo = payload;
  },
  UPDATE_USER_TOKEN(state, payload) {
    state.token = payload;
  },
  UPDATE_USER_WS_TOKEN(state, payload) {
    state.wsToken = payload;
  },
  TOKEN_REFRESH_TASK(state, item) {
    state.tokenRefreshTask = item;
  },
  SET_USER_DEPOSIT_RULES(state, payload) {
    state.userDepositRules = payload;
  },
  SET_USER_WITHDRAWAL_RULES(state, payload) {
    state.userWithdrawalRules = payload;
  },
  SET_USER_INTERNAL_MOVEMENT_RULES(state, payload) {
    state.userInternalMovementRules = payload;
  },
  SET_USER_EXCHANGE_RULES(state, payload) {
    state.userExchangeRules = payload;
  },
  SET_USER_EXCHANGE_LIMITS(state, payload) {
    state.userExchangeLimits = payload;
  },
  SET_USER_EXCHANGE_FEES(state, payload) {
    state.userExchangeFees = payload;
  },
  RESET_USER_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_USER_DEPOSIT_LIMITS(state, payload) {
    Object.assign(state.userLimits.deposit, payload);
  },
  SET_USER_WITHDRAW_LIMITS(state, payload) {
    Object.assign(state.userLimits.withdrawal, payload);
  },
  SET_USER_INTERNAL_MOVEMENT_LIMITS(state, payload) {
    Object.assign(state.userLimits.internalMovement, payload);
  },
  SET_USER_DEPOSIT_FEES(state, payload) {
    Object.assign(state.userFees.deposit, payload);
  },
  SET_USER_WITHDRAW_FEES(state, payload) {
    Object.assign(state.userFees.withdrawal, payload);
  },
  SET_USER_INTERNAL_MOVEMENT_FEES(state, payload) {
    Object.assign(state.userFees.internalMovement, payload);
  },
  SET_USER_API_SETTINGS(state, payload) {
    Object.assign(state.userApiSettings, payload);
  },
  SET_USER_API_KEYS(state, payload) {
    state.userApiKeys = payload;
  },
  SET_USER_IP_WHITELIST(state, payload) {
    state.userWhiteIpList = payload;
  },
  UPDATE_ORDERS_HISTORY(state, payload) {
    state.ordersHistory = Object.assign({}, payload);
  },
  SET_USER_SUB_ACCOUNTS(state, payload) {
    state.userSubAccounts = payload;
    if (payload && payload.length) {
      state.userSubAccountId = payload[0].id;
    }
  },
  SET_USER_MODULE_PENDING(state, boolean) {
    state._userModulePending = boolean;
  },
  SET_USER_MODULE_LOADED(state, boolean) {
    state._userModuleLoaded = boolean;
  },
  SET_ORDER_HISTORY_PENDING(state, boolean) {
    state._ordersHistoryPending = boolean;
  },
  SET_ORDER_HISTORY_LOADED(state, boolean) {
    state._ordersHistoryLoaded = boolean;
  },
  CHANGE_HISTORY_PAGE(state, page) {
    state.historyFilters.page = page;
  },
  SET_HISTORY_PAGES_COUNT(state, count) {
    state.ordersHistoryPageCount = count;
  },
  POPULATE_HISTORY_FILTER(state, data) {
    state.historyFilters[data.field] = data.value;
  },
  POPULATE_HISTORY_DATE_FILTER(state, data) {
    state.historyDateFilter[data.field] = data.value;
  },
  UPDATE_USER_TOTP_STATUS(state, payload) {
    state.userTotpEnabled = payload;
  },
  SET_CURRENCIES_LIST_TO_VERIFY_WITHDRAWAL(state, payload) {
    state.currenciesVerifyWithdrawList = payload;
  },
  SET_FINANCE_COOKIES_ACCEPTED(state) {
    state._financeCookiesAccepted = true;
    localStorage.setItem('finance_cookies_accepted', true);
  },
  SET_INVOICING_PROCESSING_RULES(state, payload) {
    state.invoice_order_processing_rules = payload;
  },
  setOrdersHistoryForXLSX(state, payload) {
    state.ordersHistoryForXLSX = Object.assign({}, payload);
  },
  SET_GENERAL_FEES_LIMITS(state, payload) {
    state.generalFeesLimits = payload;
    state.generalFeesLimitsFlatten = flattenObj(JSON.parse(JSON.stringify(payload)));
  },
  SET_CHANGE_VERIFICATION_STEP(state, payload) {
    console.log(payload, 'payload');
    state.changeVerificationMethod.step = payload
  }
};

export default mutations;
