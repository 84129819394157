<template functional>
  <svg width="13" height="11" viewBox="0 0 13 11" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.4266 5.58712C10.1589 2.3509 5.71797 1.54775 2.48175 3.81546C1.79671 4.2879 1.18253 4.90208 0.710092 5.58712L0.568359 5.79972L0.710092 6.01231C2.97781 9.24853 7.41875 10.0517 10.655 7.78397C11.34 7.31153 11.9542 6.69735 12.4266 6.01231L12.5684 5.79972L12.4266 5.58712ZM6.56836 8.3509C4.56049 8.3509 2.6471 7.40601 1.44238 5.79972C3.59198 2.96507 7.63135 2.39814 10.466 4.54775C10.9384 4.90208 11.3636 5.32728 11.718 5.79972C10.4896 7.40601 8.57623 8.3509 6.56836 8.3509Z"
        fill="#2AF3F3"
      />
      <path
        d="M6.63929 3.7207C5.48181 3.7207 4.56055 4.64196 4.56055 5.79944C4.56055 6.95692 5.48181 7.87818 6.63929 7.87818C7.79677 7.87818 8.71803 6.95692 8.71803 5.79944C8.69441 4.66558 7.77315 3.74433 6.63929 3.7207ZM6.63929 7.19314C5.88338 7.19314 5.26921 6.57897 5.26921 5.82307C5.26921 5.06716 5.88338 4.45299 6.63929 4.45299C7.39519 4.45299 8.00937 5.06716 8.00937 5.82307C7.98575 6.57897 7.39519 7.19314 6.63929 7.19314Z"
        fill="#2AF3F3"
      />
      <path
        d="M6.63939 6.57859C7.06991 6.57859 7.41892 6.22958 7.41892 5.79906C7.41892 5.36854 7.06991 5.01953 6.63939 5.01953C6.20887 5.01953 5.85986 5.36854 5.85986 5.79906C5.85986 6.22958 6.20887 6.57859 6.63939 6.57859Z"
        fill="#2AF3F3"
      />
    </g>
    <path
      d="M7.3745 5.78272L11.1774 1.97979C11.4238 1.73339 11.4238 1.33261 11.1774 1.08621C10.931 0.839805 10.5302 0.839805 10.2838 1.08621L6.48092 4.88913L2.678 1.08324C2.43159 0.836837 2.03082 0.836837 1.78441 1.08324C1.53801 1.32964 1.53801 1.73042 1.78441 1.97682L5.58734 5.77975L1.78441 9.58564C1.53801 9.83205 1.53801 10.2328 1.78441 10.4792C1.9091 10.6039 2.06941 10.6633 2.23269 10.6633C2.39597 10.6633 2.55628 10.6009 2.68096 10.4792L6.48389 6.6763L10.2868 10.4792C10.4115 10.6039 10.5718 10.6633 10.7351 10.6633C10.8984 10.6633 11.0587 10.6009 11.1834 10.4792C11.4298 10.2328 11.4298 9.83205 11.1834 9.58564L7.3745 5.78272Z"
      fill="#2AF3F3"
    />
    <defs>
      <clipPath>
        <rect width="12" height="6.54331" fill="white" transform="translate(0.568359 2.51562)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
