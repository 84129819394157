<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0151 3.35849L10.9994 6.89655C10.9984 7.12481 10.815 7.30823 10.5982 7.29778C10.3814 7.28732 10.1881 7.11699 10.2005 6.90009L10.2119 4.32073L3.40253 11.1301C3.24204 11.2906 2.99095 11.2917 2.83187 11.1327C2.6728 10.9736 2.67391 10.7225 2.8344 10.562L9.63233 3.76407L7.08727 3.76393C6.859 3.76494 6.6772 3.58314 6.68958 3.36624C6.69008 3.25211 6.73614 3.1606 6.81638 3.08035C6.89663 3.00011 6.98814 2.95405 7.10227 2.95355L10.6175 2.93798C10.8343 2.94843 11.0161 3.13023 11.0151 3.35849Z"
      fill="#5ABC67"
      stroke="#5ABC67"
      stroke-width="0.660209"
    />
  </svg>
</template>

<script>
export default {};
</script>
