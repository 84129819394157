<template functional>
  <svg :width="props.width" :height="props.height" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1693_1015)">
          <path d="M4.45156 6.24414L2.44922 4.29314L3.01398 3.72838L4.45156 5.16596L8.71295 1.05859L9.27771 1.57201L4.45156 6.24414Z" :fill="props.fillColor"/>
          <path d="M4.65873 9.11921C2.14297 9.11921 0.140625 7.06553 0.140625 4.60111C0.140625 2.13669 2.14297 0.0830078 4.65873 0.0830078C5.42886 0.0830078 6.19899 0.288376 6.86644 0.647771L7.22583 0.853139L6.81509 1.52059L6.4557 1.31522C5.94228 1.00717 5.27483 0.853139 4.65873 0.853139C2.60504 0.853139 0.910756 2.54743 0.910756 4.60111C0.910756 6.65479 2.60504 8.34908 4.65873 8.34908C6.71241 8.34908 8.4067 6.65479 8.4067 4.60111C8.4067 4.39574 8.4067 4.13903 8.35536 3.93366L8.30401 3.57427L9.07415 3.42024L9.12549 3.77964C9.17683 4.08769 9.22817 4.3444 9.22817 4.60111C9.17683 7.06553 7.12315 9.11921 4.65873 9.11921Z" :fill="props.fillColor"/>
      </g>
      <defs>
          <clipPath id="clip0_1693_1015">
              <rect width="9.13889" height="9.03621" fill="white" transform="translate(0.140625 0.0830078)"/>
          </clipPath>
      </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
    height: {
      type: Number,
      default: 10,
    },
    width: {
      type: Number,
      default: 10,
    },
  },
};
</script>
