import { isEmpty } from 'ramda';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper'
import { getCurrenciesListToHide } from "@/utils/helpers"

export default balances => {
  if (typeof balances === 'object' && !isEmpty(balances)) {
    return Object.values(balances).map(item => {
      const currencyType = isCrypto(item.currency) ? 'crypto' : 'fiat'
      return {
        ...item,
        currencyType,
      };
    }).filter(currency => getCurrenciesListToHide().every(el=>el !== currency.currency) );
  }
  return null;
};