<template functional>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <title />
    <g data-name="29-Banking-Atm" id="_29-Banking-Atm">
      <path
        :fill="props.fillColor"
        d="M15,38a4,4,0,0,1,4,4,1,1,0,0,0,1,1h8a1,1,0,0,0,1-1,4,4,0,0,1,4-4,1,1,0,0,0,1-1V10H32v8a10,10,0,0,0-16,0V10H14V37A1,1,0,0,0,15,38Zm9-22a8,8,0,1,1-8,8A8,8,0,0,1,24,16ZM16,30a10,10,0,0,0,16,0v6.11A6,6,0,0,0,27.08,41H20.92A6,6,0,0,0,16,36.08Z"
      />
      <rect :fill="props.fillColor" height="2" width="2" x="32" y="41" />
      <rect :fill="props.fillColor" height="2" width="2" x="14" y="41" />
      <path
        :fill="props.fillColor"
        d="M47,0H1A1,1,0,0,0,0,1V13a1,1,0,0,0,.29.71l3,3A1,1,0,0,0,4,17H9V45a3,3,0,0,0,3,3H36a3,3,0,0,0,3-3V17h5a1,1,0,0,0,.71-.29l3-3A1,1,0,0,0,48,13V1A1,1,0,0,0,47,0ZM9,9H6V7H9ZM37,45a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1V7H37Zm9-32.41L43.59,15H39V11h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H5A1,1,0,0,0,4,6v4a1,1,0,0,0,1,1H9v4H4.41L2,12.59V2H46ZM39,9V7h3V9Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
