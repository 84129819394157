<template functional>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.17188 1.17188H3.27734V0H0V3.27734H1.17188V1.17188Z" :fill="props.fillColor" />
    <path d="M16.7213 0V1.17188H18.8268V3.27734H19.9986V0H16.7213Z" :fill="props.fillColor" />
    <path d="M1.17188 16.7227H0V20H3.27734V18.8281H1.17188V16.7227Z" :fill="props.fillColor" />
    <path d="M18.8268 18.8281H16.7213V20H19.9986V16.7227H18.8268V18.8281Z" :fill="props.fillColor" />
    <path d="M2.69278 9.24219H9.24356V2.69141H2.69278V9.24219ZM3.86465 3.86328H8.07169V8.07031H3.86465V3.86328Z" :fill="props.fillColor" />
    <path
      d="M17.3099 10.7578H10.7592V17.3086H17.3099V10.7578ZM11.931 11.9297H16.1381V13.4492H13.4467V16.1406H11.9271L11.931 11.9297ZM14.6224 16.1367V14.6172H16.142V16.1367H14.6224Z"
      :fill="props.fillColor"
    />
    <path
      d="M8.07169 13.4492H6.55215V10.7578H2.69278V17.3086H9.24356V11.3438H8.07169V13.4492ZM8.07169 16.1367H3.86465V11.9297H5.38419V14.6211H8.07559V16.1367H8.07169Z"
      :fill="props.fillColor"
    />
    <path d="M5.37927 5.37988H6.55115V6.55176H5.37927V5.37988Z" :fill="props.fillColor" />
    <path
      d="M11.931 3.86328H13.4506V6.55469H16.142V8.07422H10.7592V9.24609H17.3099V2.69141H10.7592V6.54297H11.931V3.86328ZM14.6224 3.86328H16.142V5.38281H14.6224V3.86328Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0F1E30',
    },
  },
};
</script>
