const userToken = localStorage.getItem('kauri_finance_token');
const cookiesAccepted = localStorage.getItem('finance_cookies_accepted');

const getDefaultState = () => {
  return {
    token: userToken || '',
    wsToken: '',
    accessToken: '',
    userInfo: null,
    userDepositRules: null,
    userWithdrawalRules: null,
    userInternalMovementRules: null,
    userExchangeRules: null,
    tokenRefreshTask: null,
    userExchangeLimits: null,
    userApiKeys: [],
    userWhiteIpList: [],
    currenciesVerifyWithdrawList: [],
    invoice_order_processing_rules: '',
    userFees: {
      deposit: {},
      withdrawal: {},
      internalMovement: {},  
    },
    userLimits: {
      deposit: {},
      withdrawal: {},
      internalMovement: {},
    },
    userApiSettings: {},
    userSubAccounts: [],
    userSubAccountId: '',
    historyFilters: {
      page: 1,
      currency: '',
      order_type: '',
      order_status: '',
      from_timestamp: '',
      till_timestamp: '',
    },
    historyDateFilter: {
      from_timestamp: '',
      till_timestamp: '',
    },
    ordersHistory: {},
    ordersHistoryForXLSX: {},
    ordersHistoryPageCount: 1,
    userTotpEnabled: false,
    _ordersHistoryPending: false,
    _ordersHistoryLoaded: false,
    _userModulePending: false,
    _userModuleLoaded: false,
    _financeCookiesAccepted: cookiesAccepted || false,
    generalFeesLimits: {},
    generalFeesLimitsFlatten: {},
    changeVerificationMethod: {
      step: 1
    }
  };
};

const state = getDefaultState();

export { getDefaultState, state };
