<template functional>
  <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.2625 0.406266C3.4375 -0.0874845 2.45625 -0.131234 1.6 0.281266L15.9688 14.6813L20.6688 9.96251L4.2625 0.406266ZM0.28125 1.61252C0.1 1.99377 0 2.42502 0 2.86252V29.2C0 29.6438 0.1 30.0625 0.28125 30.4375L14.6438 16.0063L0.28125 1.61252ZM15.9688 17.3375L1.625 31.7438C2.00625 31.9188 2.4125 32 2.8125 32C3.3125 32 3.8125 31.8813 4.2625 31.6063L20.6688 22.0438L15.9688 17.3375ZM26.9125 13.5938C26.9062 13.5875 26.9 13.5875 26.8937 13.5813L22.3375 10.9313L17.2875 16.0063L22.3375 21.0688C22.3375 21.0688 26.9 18.4063 26.9062 18.4063C27.7563 17.8938 28.2563 16.9938 28.2563 16C28.2563 15.0063 27.7563 14.1125 26.9125 13.5938Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>
