import modifyBalance from "./_helpers/modifyBalance";

const getters = {

  userBalances: state => modifyBalance( state?.balance ),
  isBalancesLoaded: ( state, getters, rootState ) => state._userBalancesModuleLoaded && rootState.user._userModuleLoaded,
  isBalancesPending: ( state, getters, rootState ) => state._userBalancesModulePending && rootState.user._userModulePending,
  getterFiatBalance: ( state, getters ) => getters.userBalances?.filter( item => item?.currencyType === "fiat" ),
  getterCryptoBalance: ( state, getters ) => getters.userBalances?.filter( item => item?.currencyType === "crypto" ),
  sortedGetterCryptoAndFiatBalance: ( state, getters ) => {
    const isNeedSortByCurrency = false
    if (isNeedSortByCurrency) {
      const cryptoNotZeroBalance = getters.getterCryptoBalance.filter((item) => item?.[item?.currency]?.total > 0).sort( ( a, b ) => b?.BTC?.total - a?.BTC?.total );
      const cryptoZeroBalance = getters.getterCryptoBalance.filter((item) => item?.[item?.currency]?.total <= 0);
      const fiatNotZeroBalance = getters.getterFiatBalance.filter((item) => item?.[item?.currency]?.total > 0).sort( ( a, b ) => b?.BTC?.total - a?.BTC?.total );
      const fiatZeroBalance = getters.getterFiatBalance.filter((item) => item?.[item?.currency]?.total <= 0);
      return [...cryptoNotZeroBalance, ...fiatNotZeroBalance, ...cryptoZeroBalance, ...fiatZeroBalance]
    } else {
      return getters.userBalances.filter( item => item?.currency !== "RUB" ).sort( ( a, b ) => b?.BTC?.total - a?.BTC?.total );
    }
  }
};

export default getters;
