<template functional>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8766 15H2.56953C1.70273 15 1 14.2973 1 13.4305V2.56953C1 1.70273 1.70273 1 2.56953 1H13.4305C14.2973 1 15 1.70273 15 2.56953V10.8738C15 11.0352 14.8687 11.1664 14.7074 11.1664C14.5461 11.1664 14.4148 11.0352 14.4148 10.8738V2.56953C14.4148 2.02539 13.9719 1.58242 13.4277 1.58242H2.56953C2.02539 1.58242 1.58242 2.02539 1.58242 2.56953V13.4305C1.58242 13.9746 2.02539 14.4176 2.56953 14.4176H10.8738C11.0352 14.4176 11.1664 14.5488 11.1664 14.7102C11.1664 14.8687 11.0352 15 10.8766 15Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.4"
    />
    <path
      d="M10.8746 15.0003C10.8363 15.0003 10.798 14.9921 10.7625 14.9784C10.6531 14.9347 10.582 14.828 10.582 14.7077V12.1511C10.582 11.287 11.2848 10.5815 12.1516 10.5815H14.7082C14.8258 10.5815 14.9324 10.6526 14.9789 10.762C15.0254 10.8714 14.998 10.9972 14.916 11.0792L11.0797 14.9155C11.025 14.9702 10.9484 15.0003 10.8746 15.0003ZM12.1516 11.1667C11.6074 11.1667 11.1645 11.6097 11.1645 12.1538V14.005L14.0027 11.1667H12.1516Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.4"
    />
    <path
      d="M6.80879 11.4727C6.73223 11.4727 6.6584 11.4426 6.60098 11.3879L3.41816 8.20783C3.30332 8.09299 3.30332 7.90978 3.41816 7.79494C3.53301 7.68009 3.71621 7.68009 3.83105 7.79494L6.79238 10.7563L12.1572 4.62579C12.2639 4.50275 12.4471 4.49181 12.5701 4.59845C12.6904 4.70509 12.7041 4.88829 12.5975 5.01134L7.02754 11.3742C6.97559 11.4344 6.89902 11.4699 6.81699 11.4727C6.81426 11.4727 6.81152 11.4727 6.80879 11.4727Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#21BF73',
    },
  },
};
</script>
