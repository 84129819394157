import Vue from 'vue';

export const showAppNotification = (type = 'success', data) => {
  const text = typeof data === 'string' ? data : data.response.data.detail;
  Vue.prototype.$notify({
    type,
    text,
    group: 'main',
  });
};

export default {};
