<template functional>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6875 14C5.64375 14 5.55625 14 5.5125 13.9563C5.3375 13.9125 5.25 13.7375 5.25 13.5625V11.375H4.8125C2.14375 11.375 0 9.23125 0 6.5625V4.8125C0 2.14375 2.14375 0 4.8125 0H9.1875C11.8562 0 14 2.14375 14 4.8125V6.5625C14 9.23125 11.8562 11.375 9.1875 11.375H8.4875L5.99375 13.8688C5.90625 13.9563 5.81875 14 5.6875 14ZM4.8125 0.875C2.625 0.875 0.875 2.625 0.875 4.8125V6.5625C0.875 8.75 2.625 10.5 4.8125 10.5H5.6875C5.95 10.5 6.125 10.675 6.125 10.9375V12.5125L8.00625 10.6312C8.09375 10.5437 8.18125 10.5 8.3125 10.5H9.1875C11.375 10.5 13.125 8.75 13.125 6.5625V4.8125C13.125 2.625 11.375 0.875 9.1875 0.875H4.8125Z"
      :fill="props.fillColor"
    />
    <path d="M11.375 5.25H2.625V6.125H11.375V5.25Z" :fill="props.fillColor" />
    <path d="M10.5 3.0625H3.5V3.9375H10.5V3.0625Z" :fill="props.fillColor" />
    <path d="M10.5 7.4375H3.5V8.3125H10.5V7.4375Z" :fill="props.fillColor" />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#2AF3F3',
    },
  },
};
</script>
