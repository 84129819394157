import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index.js';
import routes from '@/router/routes/index.js';
import middlewarePipeline from '@/router/middleware/middlewarePipeline.js';
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
