import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import user from './user';
import balance from './balance';
import exchange from './exchange';
import depositAndWithdrawal from './depositAndWithdrawal';
import settings from './settings';
import providers from './providers';
import invoicing from './invoicing';
import funds from './funds';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    balance,
    exchange,
    settings,
    providers,
    depositAndWithdrawal,
    invoicing,
    funds,
  },
});
