import { getDefaultState } from './state';

const mutations = {
  UPDATE_CASHBOX_FORM(state, payload) {
    state.depositAndWithdrawalCashboxForm[payload.field] = payload.value;
  },
  UPDATE_CASHBOX_BREINROCK_FORM(state, payload) {
    state.depositAndWithdrawalCashboxBreinrockForm[payload.field] = payload.value;
  },
  UPDATE_WITHDRAW_CRYPTO_FORM(state, payload) {
    state.withdrawCryptoForm[payload.field] = payload.value;
  },
  UPDATE_WITHDRAW_FIAT_GATEWAY_FORM(state, payload) {
    state.withdrawFiatGatewayForm[payload.field] = payload.value;
  },
  UPDATE_WITHDRAW_SEPA_FORM(state, payload) {
    const fields = payload.field.split('.');
    if (fields.length === 1) {
      state.withdrawSepaForm[fields[0]] = payload.value;
    } else {
      state.withdrawSepaForm[fields[0]][fields[1]][fields[2]] = payload.value;
    }
  },
  UPDATE_WITHDRAW_SEPA_FORM_VERIFIED(state, payload) {
    const fields = payload.field.split('.');
    if (fields.length === 1) {
      state.withdrawSepaFormVerified[fields[0]] = payload.value;
    } else {
      state.withdrawSepaFormVerified[fields[0]][fields[1]][fields[2]] = payload.value;
    }
  },
  UPDATE_WITHDRAW_SWIFT_FORM(state, payload) {
    const fields = payload.field.split('.');
    if (fields.length === 1) {
      state.withdrawSwiftForm[fields[0]] = payload.value;
    } else {
      state.withdrawSwiftForm[fields[0]][fields[1]][fields[2]] = payload.value;
    }
  },
  UPDATE_INTERNAL_MOVEMENT_FORM(state, payload) {
    state.internalMovementForm[payload.field] = payload.value;
  },
  RESET_DEPOSIT_AND_WITHDRAWAL_MODULE(state) {
    Object.assign(state, getDefaultState());
  },
  UPDATE_DEPOSIT_PBT_FORM(state, payload) {
    state.depositPbtPay[payload.field] = payload.value;
  },
};

export default mutations;
