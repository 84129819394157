<template functional>
  <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.34766 2.91797L2.34766 20.918L4.18099 20.918L4.18099 2.91797L2.34766 2.91797Z" :fill="props.fillColor" />
    <path d="M3.26465 0.666668L5.76465 4.5L0.764648 4.5L3.26465 0.666668Z" :fill="props.fillColor" />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#000',
    },
  },
};
</script>
