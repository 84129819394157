<template functional>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8027 2.19727C11.3877 0.779297 9.50391 0 7.5 0C5.49609 0 3.6123 0.779297 2.19727 2.19727C0.779297 3.6123 0 5.49609 0 7.5C0 9.50391 0.779297 11.3877 2.19727 12.8027C3.61523 14.2207 5.49609 15 7.5 15C9.50391 15 11.3877 14.2207 12.8027 12.8027C14.2207 11.3848 15 9.50391 15 7.5C15 5.49609 14.2207 3.6123 12.8027 2.19727ZM13.8105 7.01367L10.8252 9.99609L9.99609 9.16699L13.5439 5.61914C13.6816 6.06445 13.7725 6.53027 13.8105 7.01367ZM13.0459 4.45605L9.16699 8.33789L8.31738 7.48828L12.3574 3.44824C12.6182 3.76172 12.8496 4.09863 13.0459 4.45605ZM8.08594 1.19824C8.52832 1.23926 8.95605 1.32422 9.36621 1.45312L8.08594 2.7334V1.19824ZM8.08594 4.3916L10.5322 1.94531C10.8867 2.13867 11.2178 2.36426 11.5283 2.62207L8.08594 6.06152V4.3916ZM7.5 13.8281C4.01074 13.8281 1.17188 10.9893 1.17188 7.5C1.17188 4.20703 3.69727 1.49707 6.91406 1.19824V7.74023L11.54 12.3662C10.4414 13.2803 9.03516 13.8281 7.5 13.8281ZM12.3662 11.54L11.6514 10.8252L13.6963 8.78027C13.4854 9.81445 13.0195 10.7549 12.3662 11.54Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
