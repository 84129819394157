import { api } from '@/axios/index';
import { showAppNotification } from '@/utils/appNotification';
import { modifyCashboxFormToRequestPayload } from './_helpers/cashboxDepositAndWithdrawalHelper';
import { removeEmptyObjectKey } from '@/utils/helpers';

const actions = {
  async apiDepositCashbox({ dispatch }, currency) {
    try {
      const response = await api.post('deposit/', {
        ...modifyCashboxFormToRequestPayload(currency),
        deposit_type: 'CASHBOX',
      });
      dispatch('resetDepositAndWithdrawalModule');
      showAppNotification('success', 'Deposit successfully created!');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  apiGatewayAddressRequest(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('/deposit/payment/', payload)
        .then(response => {
          const {data:{data:{description, status, redirect_url}}} = response
          showAppNotification(status === "FAILED" ? "error" : "success", description);
          if (redirect_url) document.location.assign(redirect_url)
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiGatewayAddressRequestProvider(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('/deposit/address/', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  async apiWithdrawCashbox({ dispatch }, currency) {
    try {
      const response = await api.post('withdrawal/', {
        ...modifyCashboxFormToRequestPayload(currency),
        withdrawal_type: 'CASHBOX',
      });
      dispatch('resetDepositAndWithdrawalModule');
      showAppNotification('success', 'Withdrawal successfully created!');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiWithdrawCashboxBreinrock({ state, dispatch }, currency) {
    const { amount, username, account_number, comment } = state.depositAndWithdrawalCashboxBreinrockForm
    try {
      const response = await api.post('withdrawal/', {
        currency,
        amount,
        withdrawal_type: "CASHBOX",
        additional_info: {
          username,
          account_number,
        },
        comment,
      });
      dispatch('resetDepositAndWithdrawalModule');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiDepositCashboxBreinrock({ state, dispatch }, currency) {
    const { amount, username, account_number, comment } = state.depositAndWithdrawalCashboxBreinrockForm
    try {
      const response = await api.post('deposit/', {
        currency,
        amount,
        deposit_type: "CASHBOX",
        additional_info: {
          username,
          account_number,
        },
        comment,
      });
      dispatch('resetDepositAndWithdrawalModule');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiWithdrawCrypto({ state, rootState, dispatch }) {
    const isEmailConfirmationRequired = rootState.user.userApiSettings?.IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED;
    try {
      if (!isEmailConfirmationRequired) {
        const response = await api.post('withdrawal/', state.withdrawCryptoForm);
        dispatch('resetDepositAndWithdrawalModule');
        return response;
      } else {
        const response = await api.post('withdrawal/request/create/', state.withdrawCryptoForm);
        dispatch('resetDepositAndWithdrawalModule');
        return response;
      }
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  // probably this api action is 100% similar to apiWithdrawCrypto, but for now it stays separately
  async apiWithdrawFiatGateway({ state, rootState, dispatch }) {
    const isEmailConfirmationRequired = rootState.user.userApiSettings?.IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED;
    try {
      const response = await api.post('withdrawal/', state.withdrawFiatGatewayForm);
      dispatch('resetDepositAndWithdrawalModule');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiWithdrawSepa({ state, dispatch }) {
    const { amount, withdrawal_type, currency, ...rest } = state.withdrawSepaForm;
    console.log(rest);
    const bank_details = {
      name: rest.beneficiary_name,
      bic: rest.beneficiary_account_number,
      address: rest.beneficiary_address,
    };
    const customer_details = {
      ref_message: rest.payment_details,
    };
    try {
      const response = await api.post('withdrawal/', {
        amount,
        withdrawal_type,
        currency,
        additional_info: {
          bank_details,
          customer_details,
        },
      });
      dispatch('resetDepositAndWithdrawalModule');
      showAppNotification('success', 'Withdrawal successfully created!');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  async apiWithdrawSwiftSepa({ state, dispatch }, methodName) {
    let withdrawalForm = {};
    if (methodName == 'SWIFT') {
      withdrawalForm = state.withdrawSwiftForm;
    } else if (methodName == 'SEPA') {
      withdrawalForm = state.withdrawSepaForm;
    }
    let request = JSON.parse(JSON.stringify(withdrawalForm));
    request = removeEmptyObjectKey(request)
    try {
      const response = await api.post('withdrawal/', request);
      dispatch('resetDepositAndWithdrawalModule');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },


  async apiWithdrawSepaVerified({ state, dispatch }) {
    let request = JSON.parse(JSON.stringify(state.withdrawSepaFormVerified));
    request = removeEmptyObjectKey(request)
    delete request.step
    try {
      const response = await api.post('withdrawal/', request);
      dispatch('resetDepositAndWithdrawalModule');
      return response;
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },




  async apiInternalMovement({ state, rootState, dispatch }) {
    const { comment, amount, currency, destination_account_email, totp_code } = state.internalMovementForm;
    const IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED = rootState.user.userApiSettings?.IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED;
    const currencyNeedsToBeVerified = rootState.user.currenciesVerifyWithdrawList.includes( currency )
    // const isEmailConfirmationRequired = IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED && currencyNeedsToBeVerified
    // TODO: need fix after change api
    const isEmailConfirmationRequired = false;
    const request = {
      comment,
      amount,
      currency,
      destination_account_email,
    }
    try {
      if (isEmailConfirmationRequired) {
        const response = await api.post('internal_movement/request/create/', request);
        dispatch('resetDepositAndWithdrawalModule');
        return response;
      } else {
        if (totp_code) {
          request.totp_code = totp_code;
        }
        const response = await api.post('internal_movement/', request);
        dispatch('resetDepositAndWithdrawalModule');
        return response;
      }
    } catch (error) {
      showAppNotification('error', error.response.data.detail);
      throw new Error(error.response.data.detail);
    }
  },
  apiVerifyWithdrawByEmail({ dispatch }, request) {
    return new Promise((resolve, reject) => {
      api
        .post('withdrawal/request/submit/', request)
        .then(response => {
          dispatch('resetDepositAndWithdrawalModule');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  apiVerifyInternalMovementByEmail({ dispatch }, request) {
    return new Promise((resolve, reject) => {
      api
        .post('internal_movement/request/submit/', request)
        .then(response => {
          dispatch('resetDepositAndWithdrawalModule');
          resolve(response);
        })
        .catch(error => {
          showAppNotification('error', error.response.data.detail);
          reject(error);
        });
    });
  },
  resetDepositAndWithdrawalModule({ commit }) {
    commit('RESET_DEPOSIT_AND_WITHDRAWAL_MODULE');
  },
  updateCashboxForm({ commit }, payload) {
    commit('UPDATE_CASHBOX_FORM', payload);
  },
  updateCashboxBreinrockForm({ commit }, payload) {
    commit('UPDATE_CASHBOX_BREINROCK_FORM', payload);
  },
  updateWithdrawCryptoForm({ commit }, payload) {
    commit('UPDATE_WITHDRAW_CRYPTO_FORM', payload);
  },
  updateWithdrawFiatGatewayForm({ commit }, payload) {
    commit('UPDATE_WITHDRAW_FIAT_GATEWAY_FORM', payload);
  },
  updateWithdrawSepaForm({ commit }, payload) {
    commit('UPDATE_WITHDRAW_SEPA_FORM', payload);
  },
  updateWithdrawSepaFormVerified({ commit }, payload) {
    commit('UPDATE_WITHDRAW_SEPA_FORM_VERIFIED', payload);
  },
  updateWithdrawSwiftForm({ commit }, payload) {
    commit('UPDATE_WITHDRAW_SWIFT_FORM', payload);
  },
  updateInternalMovementForm({ commit }, payload) {
    commit('UPDATE_INTERNAL_MOVEMENT_FORM', payload);
  },
  updateDepositPbtForm({ commit }, payload) {
    commit('UPDATE_DEPOSIT_PBT_FORM', payload);
  },
};

export default actions;
