<template>
  <span class="InfoPseudoIcon" :class="[`InfoPseudoIcon--${color}`]"><span>i</span></span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.InfoPseudoIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2dca73;
  display: inline-block;
  position: relative;
  margin-right: 4px;
  cursor: pointer;
  span {
    text-align: center;
    color: #000;
    font-size: 8px;
    position: absolute;
    right: 45%;
    top: 50%;
    transform: translate(50%, -50%);
  }
  &--white {
    background: #fff;
    span {
      color: #5abc67;
    }
  }
  &--red {
    background: #ff556d;
    span {
      color: #221b2a;
    }
  }
}
</style>
