import { api } from '@/axios/index';

const actions = {
  // TODO: this public request is only implemented to find out app status (OK or in 'tech works');
  // so we need to find a right way to do it later
  toggleMobileMenu({ commit }) {
    commit('TOGGLE_MOBILE_MENU');
  },
  setMobileMenuShown({ commit }, boolean) {
    commit('SET_MOBILE_MENU_SHOWN', boolean);
  },
  setWindowInnerWidth({ commit }, width) {
    commit('SET_WINDOW_INNER_WIDTH', width);
  },
  apiGetStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('statistics?statistics_type=OPERATIONS_COUNT_PER_MONTH')
        .then(() => {
          resolve();
        })
        .catch(e => {
          if (e.response.status === 503) {
            commit('SET_APP_STATUS_TO_MAINTENANCE', true);
          } else {
            commit('SET_APP_STATUS_TO_MAINTENANCE', false);
          }
          reject(e);
        });
    });
  },
  toggleThemeColor({ state, commit } ) {
    const color = state.themeColor == 'dark' ? 'light' : 'dark';
    commit('SET_THEME_COLOR', color);
  },
  updateThemeColor({ state, commit }, color = state.themeColor ) {
    commit('SET_THEME_COLOR', color);
  },
};

export default actions;
