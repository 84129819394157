<template functional>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: none"
    id="svg8"
    version="1.1"
    viewBox="0 0 10.3001 9.8653002"
    height="9.8653002"
    width="10.3001"
  >
    <metadata id="metadata14">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs id="defs12" />
    <path
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.3"
      id="path2"
      d="m 9.8235,5.25814 h -6.304 c -0.17959,0 -0.32653,-0.14694 -0.32653,-0.32653 0,-0.17959 0.14694,-0.32653 0.32653,-0.32653 h 6.304 c 0.1796,0 0.3266,0.14694 0.3266,0.32653 0,0.18163 -0.147,0.32653 -0.3266,0.32653 z"
    />
    <path
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.3"
      id="path4"
      d="m 5.26116,6.99637 c -0.08367,0 -0.16735,-0.03265 -0.23061,-0.09592 L 3.29177,5.16169 c -0.12653,-0.12653 -0.12653,-0.3347 0,-0.46123 L 5.03055,2.96169 c 0.12653,-0.12653 0.33469,-0.12653 0.46122,0 0.12653,0.12653 0.12653,0.3347 0,0.46123 L 3.98361,4.93107 5.49177,6.43923 c 0.12653,0.12653 0.12653,0.33469 0,0.46122 C 5.42851,6.96576 5.34483,6.99637 5.26116,6.99637 Z"
    />
    <path
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.3"
      id="path6"
      d="M 4.93163,9.7153 C 2.2949,9.7153 0.15000001,7.57041 0.15000001,4.93163 0.15000001,2.29286 2.2949,0.15000001 4.93163,0.15000001 6.91122,0.15000001 8.65816,1.3398 9.3827,3.18265 9.448,3.35 9.3663,3.5398 9.199,3.6051 9.03163,3.67041 8.84184,3.58878 8.77653,3.42143 8.15,1.82959 6.64184,0.80306001 4.93163,0.80306001 c -2.27551,0 -4.12856999,1.85305999 -4.12856999,4.12856999 0,2.27551 1.85305999,4.13062 4.13060999,4.13062 1.70817,0 3.21837,-1.02858 3.8449,-2.61837 C 8.84388,6.27653 9.03367,6.1949 9.201,6.2602 9.3684,6.32551 9.45,6.51531 9.3847,6.68265 8.65816,8.52551 6.91122,9.7153 4.93163,9.7153 Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
