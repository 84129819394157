<template>
  <button
    :class="[
      'outline-button OutlineButton_button',
      {
        ['OutlineButton_disabled']: disabled,
        ['OutlineButton_defaultBtn']: defaultBtn,
        ['OutlineButton_fullWidth']: fullWidth,
        ['OutlineButton_red']: red,
        'OutlineButton_button--currency': currencyType && (text.toLowerCase() === 'deposit' || text.toLowerCase() === 'withdraw'),
        
        'outline-button-disabled': disabled,
        'w-full': fullWidth,
        'outline-button-currency': currencyType && (text.toLowerCase() === 'deposit' || text.toLowerCase() === 'withdraw'),
      },
    ]"
    @click="handleClick"
    @focus="$emit('focus', true)"
    @blur="$emit('blur', false)"
    :disabled="disabled"
    ref="button"
  >
    <div class="flex justify-center items-center">
      <slot></slot>
      <span>{{ text }}</span>
      <div class="ml-4" v-if="currencyType && (text.toLowerCase() === 'deposit' || text.toLowerCase() === 'withdraw')">{{ currency }}</div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
      default: 'Submit',
    },
    withIconImg: {
      type: String,
      required: false,
      default: '',
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    brightBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencyType: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: "",
    },
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      this.$emit('click');
    },
    focusButton() {
      this.$refs.button.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './OutlineButton.scss';
</style>
