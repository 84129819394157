<template functional>
  <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.06455 3.02445C1.1087 3.07212 1.17292 3.09595 1.23713 3.09595C1.30135 3.09595 1.36556 3.07212 1.40971 3.02445L3.4004 1.05037L5.3951 3.02445C5.43925 3.07212 5.50346 3.09595 5.56768 3.09595C5.63189 3.09595 5.69611 3.07212 5.74026 3.02445C5.78842 2.98076 5.8125 2.91721 5.8125 2.85366C5.8125 2.7901 5.78842 2.72655 5.74026 2.68286L3.57699 0.541955C3.52883 0.494291 3.46863 0.470459 3.40441 0.470459C3.3402 0.470459 3.27598 0.498263 3.23183 0.541955L1.06857 2.68286C1.0204 2.72655 0.99231 2.7901 0.99231 2.85366C0.99231 2.91721 1.01639 2.97679 1.06455 3.02445Z"
      :fill="props.fillColor"
      :stroke="props.fillColor"
      stroke-width="0.48"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
