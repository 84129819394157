import { showAppNotification } from "@/utils/appNotification"
import { isCrypto } from "@/store/balance/_helpers/currencyTypeHelper"
import { formatOrderTypeToHistoryView } from "@/components/History/HistoryTable/_helpers/orderTypeFormatter"

export const currencyFullName = currency => {
  const names = {
    USDT: 'TetherUS',
    USD: 'Dollar',
    EUR: 'Euro',
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    UAH: 'Ukraine Hryvnia',
    LTC: 'Litecoin',
    USDC: 'USD Coin',
    BNB: 'Binance Coin',
    DOGE: 'Dogecoin',
    BRNC: 'Breincoin',
  }

  return names[currency]
}

export const helpersGetNotificationPageUrl = ( notificationType ) => {
  const domain = window.location.origin || 'http://kauri.finance'
  return `${domain}/notifications?notificationType=${notificationType}`
}

export const copyToClipboard = value => {
  const dummyInput = document.createElement( "input" )
  document.body.appendChild( dummyInput )
  dummyInput.setAttribute( "value", value )
  dummyInput.select()
  document.execCommand( "copy" )
  document.body.removeChild( dummyInput )
  showAppNotification( "success", "Copied to clipboard" )
}

export const calculateFeeAmount = ( { amount, feeObject } ) => {
  const feeByPercent = Number( amount ) * ( feeObject.percent_fee / 100 )
  const feeByPercentAndStatic = feeByPercent + feeObject.static_fee
  return feeByPercentAndStatic
}

export const roundingCurrency = ( { currencyAmount, currency } ) => {
  const checkNumber = ( num ) => {
    const newNum = String( num ).toLowerCase()
    return newNum.includes( "e-" ) || newNum.includes( "e+" )
  }
  
  const isCurrencyHaveDecimal = `${ currencyAmount }`.split( "." )?.[ 1 ]?.length > 1
  const isNumberExponential = checkNumber( currencyAmount )
  
  if ( isCurrencyHaveDecimal || isNumberExponential ) {
    currencyAmount = ( isCrypto( currency ) && currency != "USDT" ) ? Number( currencyAmount ).toFixed( 6 ) : Number( currencyAmount ).toFixed( 2 )
  }
  
  const isZero = String(currencyAmount).replaceAll( "0", "" ).replace( ".", "" ) === ""
  currencyAmount = isZero ? 0 : currencyAmount
  
  return Number( currencyAmount )
}

export const removeEmptyObjectKey = obj => {
  return Object.fromEntries(
    Object.entries( obj )
      .filter( ( [ _, v ] ) => v != null && v != "" )
      .map( ( [ k, v ] ) => [ k, v === Object( v ) ? removeEmptyObjectKey( v ) : v ] ),
  )
}

export const getCurrenciesListToHide = () => {
  return ["KZT", "CAD", "UZS", "RUB", "PLN", "NZD", "NOK", "UACB-NOV22-TRC20", "USDTOMNI", "UACB_NOV22", "UACB-NOV22-BEP20", "UCB"]
}

export const generateObjectForExportXLSX = ( item, index ) => {
  let amount,
    currency,
    feeCurrency
  const indexInCurrency = item?.currency.indexOf( "_" ),
    currencyOperationBuy = item?.currency.split( "_" )[ 0 ],
    currencyOperationSell = item?.currency.split( "_" )[ 1 ],
    currencyStatus = item?.status.includes( "WAITING" ) ? "WAITING" : item?.status.includes("CLOSED") ? "COMPLETED" : item?.status ? item?.status : "-"
  
  if ( indexInCurrency === -1 ) {
    // amount = item?.amount ? `${ item?.amount } ${ item?.currency }` : "-"
    amount = item?.amount ? `${ item?.amount }` : "-"
    currency = item?.currency ? `${ item?.currency }` : "-"
    feeCurrency = item?.currency ? `${ item?.currency }` : "-"
  } else {
    const currencyAmount = item?.currency.split( "_" )
    // amount = item?.details?.currency_to_get_amount ? `${ item?.details?.currency_to_get_amount } ${ currencyAmount[ 0 ] }` : "-"
    amount = item?.details?.currency_to_get_amount ? `${ item?.details?.currency_to_get_amount }` : "-"
    currency = item?.details?.currency_to_get_amount ? `${ currencyAmount[ 0 ] }` : "-"
    feeCurrency = item?.currency ? `${ currencyAmount[ 1 ] }` : "-"
  }
  
  const dataTime = String( item?.dt )
    .split( "." )
  const itemOrderType = formatOrderTypeToHistoryView( item )
  
  const itemObj = {
    "№": index + 1,
    "External id": item?.external_id || "-",
    "Internal ID": item?.internal_id ? item.internal_id : "-",
    "Data": item?.dt ? dataTime[ 0 ] : "-",
    "Transaction Type": item?.order_type ? itemOrderType.replace( /<[a-zA-Z>]*>|<\/[a-zA-Z>]*>/g, " " ) : "-",
    "Amount": amount,
    "Currency": currency,
    "Fee": item?.details?.fee ? `${ item?.details?.fee }` : item?.fee ? `${ item?.fee }` : "0",
    "Fee Currency": feeCurrency,
    "Comment": item?.details?.comment ? item.details.comment : "-",
    "Status": currencyStatus,
    "Transaction Hash": item?.details?.tr_hash ? item.details.tr_hash : "-"
  }
  
  return [
    itemObj,
    currencyOperationBuy,
    currencyOperationSell
  ]
}

export const flattenObj = (ob) => {
  let result = {};
  for (const i in ob) {
    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + '.' + j] = temp[j];
      }
    }
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const setFee = ({ percent_fee, static_fee, currency = '' }) => {
  if (static_fee == undefined || percent_fee == undefined) return '-';
  if (percent_fee && static_fee) {
    return percent_fee + '% + ' + static_fee + ' ' + currency;
  } else if (percent_fee && !static_fee) {
    return percent_fee + '%';
  } else if (!percent_fee && static_fee) {
    return static_fee + ' ' + currency;
  }
  return '0 ' + currency;
}