export { default as arrowLeft } from '@/assets/icons/arrows/arrowLeft';
export { default as swapArrows } from '@/assets/icons/arrows/swapArrows';
export { default as iconClose } from '@/assets/icons/arrows/iconClose';
export { default as iconCross } from '@/assets/icons/arrows/iconCross';
export { default as iconChevron } from '@/assets/icons/arrows/iconChevron';
export { default as InfoPseudoIcon } from '@/assets/icons/arrows/InfoPseudoIcon';
export { default as circleArrow } from '@/assets/icons/arrows/circleArrow';
export { default as littleRedArrow } from '@/assets/icons/arrows/littleRedArrow';
export { default as littleGreenArrow } from '@/assets/icons/arrows/littleGreenArrow';
export { default as arrowTop } from '@/assets/icons/arrows/arrowTop';
