<template functional>
  <svg :width="props.width" :height="props.height" version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g id="layer1">
      <path
        d="M 3 0 L 3 18 L 0 15 L 0 16.5 L 3.5 20 L 7 16.5 L 7 15 L 4 18 L 4 0 L 3 0 z M 7 2 L 7 3 L 20 3 L 20 2 L 7 2 z M 7 6 L 7 7 L 15 7 L 15 6 L 7 6 z M 7 10 L 7 11 L 11 11 L 11 10 L 7 10 z "
        :style="`fill: ${props.fillColor}; fill-opacity: 1; stroke: none; stroke-width: 0px`"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
    height: {
      type: Number,
      default: 12,
    },
    width: {
      type: Number,
      default: 12,
    },
  },
};
</script>
