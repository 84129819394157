const mutations = {
  UPDATE_INVOICE(state, payload) {
    state.invoice = Object.assign({}, payload);
  },
  UPDATE_INVOICE_PAYMENT_STATUS(state, payload) {
    state.invoice_payment_status = payload;
  },
  UPDATE_INVOICE_GATEWAY_FORM(state, payload) {
    state.invoiceGatewayPay[payload.field] = payload.value;
  }
};

export default mutations;
