const mutations = {
  UPDATE_USER_PROVIDERS(state, payload) {
    state.userProviders = Object.assign({}, payload);
  },
  UPDATE_USER_PROVIDERS_BALANCES(state, payload) {
    state.providersBalances = Object.assign({}, payload);
  },
  UPDATE_USER_PROVIDERS_TRANSACTIONS_HISTORY(state, payload) {
    state.providersTransactionHistory = Object.assign({}, payload);
  },
  SET_USER_PROVIDERS_TRANSACTIONS_HISTORY_PAGES_COUNT(state, count) {
    state.providersHistoryPageCount = count;
  },
  CHANGE_PROVIDERS_HISTORY_PAGE(state, page) {
    state.providersHistoryFilters.page = page;
  },
  POPULATE_PROVIDERS_HISTORY_FILTER(state, data) {
    state.providersHistoryFilters[data.field] = data.value;
  },
  POPULATE_PROVIDERS_HISTORY_DATE_FILTER(state, data) {
    state.providersHistoryDateFilter[data.field] = data.value;
  },
  SET_PROVIDERS_FEE(state, data) {
    state.providersFee = Object.assign({}, data);
  },
  UPDATE_SUPPORTED_PROVIDERS_INTEGRATIONS(state, payload) {
    state.supportedProviderIntegrations = payload;
  },
  UPDATE_ACTIVATE_PROVIDER_FORM(state, payload) {
    state.activateProviderForm[payload.field] = payload.value;
  },
};

export default mutations;
