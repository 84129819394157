<template functional>
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4277_7143)">
      <path
        d="M11.8583 3.58712C9.59055 0.350897 5.14961 -0.452253 1.91339 1.81546C1.22835 2.2879 0.614173 2.90208 0.141732 3.58712L0 3.79972L0.141732 4.01231C2.40945 7.24853 6.85039 8.05168 10.0866 5.78397C10.7717 5.31153 11.3858 4.69735 11.8583 4.01231L12 3.79972L11.8583 3.58712ZM6 6.3509C3.99213 6.3509 2.07874 5.40601 0.874016 3.79972C3.02362 0.96507 7.06299 0.398141 9.89764 2.54775C10.3701 2.90208 10.7953 3.32728 11.1496 3.79972C9.92126 5.40601 8.00787 6.3509 6 6.3509Z"
        :fill="props.fillColor"
      />
      <path
        d="M6.07093 1.7207C4.91345 1.7207 3.99219 2.64196 3.99219 3.79944C3.99219 4.95692 4.91345 5.87818 6.07093 5.87818C7.22841 5.87818 8.14967 4.95692 8.14967 3.79944C8.12605 2.66558 7.20479 1.74433 6.07093 1.7207ZM6.07093 5.19314C5.31502 5.19314 4.70085 4.57897 4.70085 3.82307C4.70085 3.06716 5.31502 2.45299 6.07093 2.45299C6.82683 2.45299 7.44101 3.06716 7.44101 3.82307C7.41739 4.57897 6.82683 5.19314 6.07093 5.19314Z"
        :fill="props.fillColor"
      />
      <path
        d="M6.07103 4.57859C6.50155 4.57859 6.85056 4.22958 6.85056 3.79906C6.85056 3.36854 6.50155 3.01953 6.07103 3.01953C5.64051 3.01953 5.2915 3.36854 5.2915 3.79906C5.2915 4.22958 5.64051 4.57859 6.07103 4.57859Z"
        :fill="props.fillColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4277_7143">
        <rect width="12" height="6.54331" fill="white" transform="translate(0 0.515625)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
