<template functional>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M9.7422 5.82948L3.48134 12.0903L0.69873 12.1897V9.40712L7.05897 3.04688L9.7422 5.82948Z" 
      :fill="props.fillColor"
    />
    <path
      d="M12.326 3.34473L10.8353 4.83541L9.34466 3.34473L8.05273 2.05281L9.54342 0.562124C10.0403 0.0652296 10.9347 0.0652296 11.4316 0.562124L12.326 1.45653C12.8229 1.95343 12.8229 2.74846 12.326 3.34473Z"
      :fill="props.fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
